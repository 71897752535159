@use "@able/web/src" as able with ($include-breakpointLG: false);

.tplus-form-container {
  margin-bottom: 0;

  &__heading__container {
    margin-bottom: 2rem;
  }
  &__heading {
    @include able.TextStyle(HeadingB);
  }
  
  &__body-copy {
    margin-top: 1rem;
    @include able.TextStyle(Base);
  }

  .tplus-jego-component.able-column.tplus-form-element {
    margin-bottom: able.spacing(spacing7x);
  }

}