.tplus-promo-tile {
  .tile-image {
    img {
      display: block;
    }
  }
  &__surface {
    position: relative;
  }
  &__lozenge {
    top: 0.5rem;
    left: 0.5rem;
    position: absolute !important;
  }
}
