@use "@able/web/src" as able;

@mixin high-emphasis-styles() {
  background-color: able.colour(interactiveForegroundNormal);
  color: $white;
  width: auto;
  outline: 1px solid transparent;
}
@mixin medium-emphasis-styles() {
  border: 1px solid able.colour(interactiveForegroundNormal);
  background-color: $white;
  color: able.colour(interactiveForegroundNormal);
  width: auto;
}

.button-high-emphasis {
  @include able.ActionButton(HighEmphasis);
  @include high-emphasis-styles();

  &:focus,
  &:hover,
  &:active {
    //overriding the able default styles
    @include high-emphasis-styles();
    //box-shadow: none;
  }
  &:focus:not(:hover) {
    box-shadow: 0 0 0 0.1875rem #005fcc;
  }
}

.button-medium-emphasis {
  @include able.ActionButton(MediumEmphasis);
  @include medium-emphasis-styles();

  &:focus,
  &:hover,
  &:active {
    //overriding the able default styles
    @include medium-emphasis-styles();
  }
  &:focus:not(:hover) {
    box-shadow: 0 0 0 0.1875rem #005fcc;
  }
}

.button-low-emphasis {
  @include able.ActionButton(LowEmphasis);
  color: $primaryColour;
  &:focus,
  &:hover,
  &:active {
    color: $primaryColour;
  }
  svg {
    color: $primaryColour;
    margin-left: 0.25rem;
    margin-right: 0.25rem;
    transition: transform 0.3s;
  }
}

.button-destructive {
  @include able.ActionButton(Destructive);
  color: $primaryColour;
  &:focus,
  &:hover,
  &:active {
    color: $primaryColour;
  }
  svg path {
    fill: $primaryColour;
  }
}

@media screen and (min-width: 48rem) {
  .button-medium-emphasis {
    min-width: 5.25rem;
  }
}
// TODO, migrate to Able
.submit-btn {
  // padding-top: 20px;
  position: relative;
  padding: 15px 0;
  text-align: center;
  font-size: 16px;
  border: 2px solid;
  border-radius: 3px;
  font-weight: bold;
  min-width: 228px;
  cursor: pointer;

  &.default {
    background: $white;
    color: $primaryColour;
  }
  //can be added more for addditional colors
}

.button-plus {
  &.member {
    color: $member-tier-normal !important;
    svg {
      path, rect {
        fill: $member-tier-normal !important; // TEMP FIX REMOVE WHEN ABLE FIXES IT
      }
    }
    &.able-ActionButton--medium-emphasis {
      border-color: $member-tier-normal !important;
      &:hover,
      &:active {
        background-color: rgba(160, 20, 140, 0.1);
      }
      &:before {
        background-color: $member-tier-normal !important;
      }
    }
    &.able-ActionButton--high-emphasis {
      color: #ffffff !important;
      background-color: $member-tier-normal !important;
    }
  }
  &.silver {
    color: $member-tier-silver !important;
    svg {
      path, rect {
        fill: $member-tier-silver !important; // TEMP FIX REMOVE WHEN ABLE FIXES IT
      }
    }
    &.able-ActionButton--medium-emphasis {
      border-color: $member-tier-silver !important;
      &:hover,
      &:active {
        background-color: rgba(112, 112, 112, 0.1);
      }
      &:before {
        background-color: $member-tier-silver !important;
      }
    }
    &.able-ActionButton--high-emphasis {
      color: #ffffff !important;
      background-color: $member-tier-silver !important;
    }
  }
  &.gold {
    color: $member-tier-gold !important;
    svg {
      path, rect {
        fill: $member-tier-gold !important; // TEMP FIX REMOVE WHEN ABLE FIXES IT
      }
    }
    &.able-ActionButton--medium-emphasis {
      border-color: $member-tier-gold !important;
      &:hover,
      &:active {
        background-color: rgba(147, 113, 59, 0.1);
      }
      &:before {
        background-color: $member-tier-gold !important;
      }
    }
    &.able-ActionButton--high-emphasis {
      color: #ffffff !important;
      background-color: $member-tier-gold !important;
    }
  }
  &.business {
    color: $member-tier-business !important;
    svg {
      path, rect {
        fill: $member-tier-business !important; // TEMP FIX REMOVE WHEN ABLE FIXES IT
      }
    }
    &.able-ActionButton--medium-emphasis {
      border-color: $member-tier-business !important;
      &:hover,
      &:active {
        background-color: rgba(160, 20, 140, 0.1);
      }
      &:before {
        background-color: $member-tier-business !important;
      }
    }
    &.able-ActionButton--high-emphasis {
      color: #ffffff !important;
      background-color: $member-tier-business !important;
    }
  }
}

// Temporary in place of tcom core
.primary-cta-18 {
  cursor: pointer;
  text-decoration: none;
  position: relative;
  padding: 1rem 0.5rem;
  margin-left: -0.5rem;
  display: inline-block;
  line-height: 1.25;
  svg {
    width: 0.75rem;
    height: 0.75rem;
    transition: transform .3s;
    transition: transform .3s,-webkit-transform .3s;
    transition: -webkit-transform .3s;
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }
}

.msg-us-btn {
  z-index: 10;
}