@use "@able/web/src" as able;
@import '~styles/base.scss';

// TODO, setup color variables. 
// Suggest to just grab directly from t.com rather than 
// updating their library since we are moving to Able.

.tplus-rich-text {
  @include able.TextStyle(Base);

  span {
    display: inline-block;
  }

  h1 {
    @include able.TextStyle(HeadingA);
    margin-bottom: 1rem;
  }

  h2 {
    @include able.TextStyle(HeadingB);
    margin-bottom: 1rem;
  }

  h3 {
    @include able.TextStyle(HeadingD);
    margin-bottom: .5rem;
  }

  p {
    display: inline-block;
    // font-size: 1rem;
    // letter-spacing: -0.008rem;
    // color: $textPrimary;
    color: #414141;
    margin: 0 0 16px 0;
    // line-height: 1.5;
    width: 100%;

    @include able.TextStyle(Base);

    font: 400 1rem/1.4 Telstra Akkurat,Helvetica Neue,Helvetica,Arial,sans-serif;
    letter-spacing: .009375rem;
  }

  b {
    @include able.TextStyle(Label);
  }


  sub,
  sup {
    @include able.TextStyle(Base);
  }

  ul {
    font-size: 1rem;
    letter-spacing: -0.008rem;
    // color: $textPrimary;
    font-family: 'Telstra Akkurat', Arial, sans-serif;
    font-weight: 300;
    line-height: 1.5;
    list-style-type: disc;
    padding-inline-start: 40px;

    ul {
      list-style-type: circle;
    }

    @include able.TextStyle(Base);

    margin: 16px 0;
  }

  ol {
    font-size: 1rem;
    letter-spacing: -0.008rem;
    // color: $textPrimary;
    font-family: 'Telstra Akkurat', Arial, sans-serif;
    font-weight: 300;
    line-height: 1.5;

    @include able.TextStyle(Base);

    margin: 16px 0;
  }

  li > :first-child {
    vertical-align: top;
  }

  hr {
    border: none;
    // background: $borderSecondary;
    height: 1px;
  }

}