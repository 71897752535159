@use "@able/web/src" as able;

.tplus-tickets-movie-selector {

    &_surface {
        border-radius: 0.5rem;
    }
    
    &_button {
        margin-top: 30px;
        > button {
            @include able.mediaQuery(breakpointMD) {
                min-width: 7rem;
                width: 100%;
            }
        }
        @include able.mediaQuery(viewportXS_plus_SM) {
            margin-top: 0;
        }
    }
}