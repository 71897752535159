@use "@able/web/src" as able with ($include-breakpointLG: false);
@import '~styles/base.scss';

// .points-history-page {
//   position: relative;

  .points-container {

    p {
      margin: revert;
    }
//     @include able.mediaQuery(breakpointMD) {
//       // @include make-col(10);
//       // @include make-col-offset(2);
//     }

//     margin-bottom: 56px;

//     .title {
//       margin: 0;
//     }
//     .points-value {
//       margin: 0;
//       padding: 24px 0;
//       font-weight: $font-weight-bold;
//       font-size: 44px;
//       color: $text-color-primary;
//     }

//     .callout {
//       font-size: 14px;
//       color: $text-color-secondary-accessible;
//     }
  }

  .activity-container {

    p, h3 {
      margin: revert;
    }
//     position: relative;
//     display: block;
//     padding-top: 12px;
//     @include able.mediaQuery(breakpointMD) {
//       // @include make-col(6);
//       // @include make-col-offset(2);
//     }

//     h3 {
//       font-size: 28px;
//       padding: 0;
//       margin: 0;
//     }

//     p {
//       font-size: 14px;
//       color: $text-color-secondary-accessible;
//     }
//   }

//   .view-history-container {
//     padding-bottom: 60px;
//   }
}

// .points-offset-div {
//   display: block;
//   width: auto;
//   height: 360px !important;
// }


