@use "@able/web/src" as able with ($include-breakpointLG: false);

.tplus-cchips__container {
  display: none;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
  margin-bottom: 24px;

  @include able.mediaQuery(breakpointMD) {
    display: flex;
  }
}

.tplus-cchips__select__container {
  display: block;
  margin-bottom: 24px;

  @include able.mediaQuery(breakpointMD) {
    display: none;
  }
}

.tplus-cchips__chip {
  @include able.TextStyle(Label);
  display: flex;
  align-items: center;
  background: #fff;
  border: solid 1px #0064d2;
  color: #0064d2;
  padding: 16px;
  height: 48px;
  border-radius: 32px;
  transition: 250ms linear;
  cursor: pointer;
}

.tplus-cchips__chip:hover {
  background: #0064d20f;
  border-color: #001e82;
  color: #001e82;
}

.tplus-cchips__chip:focus {
  box-shadow: inset 0 0 0 0.125rem #0064d2, 0 0 0 0.125rem rgb(0 100 210 / 16%);
}

.tplus-cchips__chip:active {
  color: white;
  background: #001e82;
  border-color: #001e82;
}

.tplus-cchips__chip--active {
  color: white;
  background: #001e82;
  border-color: #001e82;
}

.tplus-cchips__chip--active:focus {
  box-shadow: inset 0 0 0 0.125rem #80bdff, 0 0 0 0.125rem rgb(0 100 210 / 16%);
}

.tplus-cchips__content {
  display: block;
}

.tplus-cchips__content--hidden {
  display: none;
}