:global(.ctawrapper) {
  margin-top: auto;
  padding-top: 16px;
  .cta + .cta {
    // padding-top: 0;
    padding-left: 32px;
  }
  :global(.cta) {
    color: #a0148c;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    padding: 16px 12px;
  }
  :global(.activated-span) {
    // position: relative;

    padding: 16px 0 16px 0;
    width: 85%;
    // margin: 0 auto;
    display: flex;
    font-size: 16px;
    img,
    svg {
      position: absolute;
      top: -2px;
      margin-right: 6px;
      &.opicon {
        top: 17px;
      }
      &.info {
        fill: #0064d2;
      }
      &.success {
        fill: #008a00;
      }
    }
    svg {
      height: 1.5rem;
    }
    p {
      margin: 0;
      margin-left: 1.7rem;
    }
    :global(.activatedwrapper) {
      display: flex;
      position: relative;
      //   margin: 0 auto;
    }
  }
  :global(.sub) {
    color: #414141;
    font-size: 14px;
    letter-spacing: 0.18px;
    line-height: 20px;
  }
}
