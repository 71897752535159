@use "@able/web/src" as able with ($include-breakpointLG: false);
@import '~styles/base.scss';

.otpForm {
  *:focus {
    outline: none;
  }

  .otp-btn {
    padding: 0;
  }

  // reset number input for chrome and IE
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  // reset number input for firefox
  input[type='number'] {
    -moz-appearance: textfield;
    color: #000;
    &::-webkit-input-placeholder {
      color: #000;
      opacity: 1;
    }

    &:-moz-placeholder {
      /* Firefox 18- */
      color: #000;
      opacity: 1;
    }

    &::-moz-placeholder {
      /* Firefox 19+ */
      color: #000;
      opacity: 1;
    }

    &:-ms-input-placeholder {
      color: #000;
      opacity: 1;
    }
  }

  input {
    box-sizing: border-box;
    border-radius: 5px;
    border: 1px solid able.colour(borderNormal);
    background-color: #ffffff;
    width: 32px;
    max-width: 39.5px;
    height: 44px;
    color: #414141;
    font-size: 20px;
    letter-spacing: -0.5px;
    line-height: 19px;
    text-align: center;
    &:focus {
      border: 2px solid able.colour(borderActive);
    }
    &:hover {
     color: able.colour(azure);
      border: 1px solid able.colour(borderActive);
    }
    &:error {
      color: able.colour(azure);
       border: 1px solid able.colour(borderError);
     }
  }

  span {
    width: 8px;
  }

  .opt-validation-error {
    color: able.colour(destructiveForegroundNormal);
    margin-top: 0.25rem;
    margin-left: -4px;
    position: relative;
    display: flex;
  }
}
