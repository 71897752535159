@use "@able/web/src" as able with ($include-breakpointLG: false);
@import '~styles/base.scss';

.sub-header-holder {
  position: relative;
  .heading-top {
    &::before {
      content: ' ';
      position: absolute;
      height: 100%;
      width: 10px;
      top: -1px;
      left: -7%;
      background-color: #a0148c;
    }
  
    width: 95%;
    //Mobile First
    padding: 8px 8px 8px 16px;
    margin-bottom: 8px;
   
    .sub-title {
      @include able.TextStyle(BaseBig, Paragraph);
    }
  
    &.unauth {
      margin-top: 32px;
      .title {
        @include able.TextStyle(HeadingC);
      }

      @include able.mediaQuery(viewportXS) {
        &::before {
          left: -3.6%;
        }
      }
      @include able.mediaQuery(breakpointSM) {
        &::before {
          left: -1.4%;
        }
      }
      @include able.mediaQuery(breakpointMD) {
        transform: translateX(4.4%);
        padding-left: 32px;
        &::before {
          left: -1.9%;
        }
      }
    }

    &.auth {
      margin-top: 40px;
      .title {
        @include able.TextStyle(HeadingB);
      }
      @include able.mediaQuery(breakpointSM) {
        &::before {
          left: -1.4%;
        }
      }
    }
  }
}
