@use "@able/web/src" as able with ($include-breakpointLG: false);

.tplus-websiteFeedback__button__usabilla {
  transition: background-color 250ms, color 250ms, border 250ms, box-shadow 250ms;
  transition-timing-function: ease-in;
  border: 0;
  background-color: able.colour('interactiveInverseBackgroundNormal');
  position: fixed;
  left: 10%;
  bottom: 0;
  padding: 0 able.spacing(spacing2x);
  z-index: 10;
  cursor: pointer;
  @include able.TextStyle(FinePrint, Inverted);

  @include able.mediaQuery(breakpointMD) {
    @include able.TextStyle(Base, Inverted);
    right: 0;
    left: unset;
    bottom: 60%;
    transform: rotate(-90deg);
    transform-origin: bottom right;
    height: 48px;
  }
  &:hover {
    background-color: able.colour('interactiveInverseBackgroundActive');
  }
  &:focus {
    background-color: able.colour('interactiveInverseBackgroundActive');
    box-shadow: 0 0 0 0.1875rem #0099f8;
    outline: none;
  }
}
