@use "@able/web/src" as able with ($include-breakpointLG: false);
.cta-groups {
  .primary , .secondary {
    display: block;
    margin-top: able.spacing(spacing1x);
    a, button {
      margin-right: able.spacing(spacing3x);
      margin-top: able.spacing(spacing2x);
    }
    a:last-child {
      margin-right: 0;
    }
  }

  .secondary {
    a {
      margin-top: 0;
    }
  }
}