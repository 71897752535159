@use "@able/web/src" as able with ($include-breakpointLG: false);

.tplus-center-promo-tile {
  > div {
    padding-left:6%;
    padding-right: 6%;
  }
  .centered {
    text-align: center;
  }
  &__pink {
    background-color: able.colour(lozengeNegativeBackground);
  }

  &__grey {
    background-color: able.colour(lozengeNeutralBackground);
  }
}
