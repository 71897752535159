@use "@able/web/src" as able;

.page {
  min-height: calc(
    100vh - 286px - 54px
  ); // screen size minus height of footer and header
}

.main {
  > section {
    margin-top: 60px;
    @include able.mediaQuery(breakpointMD) {
      margin-top: 120px;
    }
    &:first-child {
      margin-top: 40px;
      @include able.mediaQuery(breakpointMD) {
        margin-top: 80px;
      }
    }
  }
}

.container {
  max-width: 1156px;
  width: auto;
  padding-right: 12px;
  padding-left: 12px;
}

// .narrow-page {
//   @include make-col-ready();
//   @include make-col(12);
//   @include media-breakpoint-up(md) {
//     @include make-col-offset(1);
//     @include make-col(5)
//   }
// }

.grey-container, .pink-container {
  margin-top: 60px;
  padding: 60px 6%;
}

.grey-container, .pink-container {
  padding: 60px 6%;
  max-width: 1336px;
  &.benefit {
    @include able.mediaQuery(breakpointMD) {
      .promo-container {
        .promo-image {
          width: 100%;
        }
        .promo-content {
          margin-right: 0;
          width: 60%;
        }
      }
    }
  }

  @include able.mediaQuery(viewportXS_plus_SM) {
    // padding: 0 24px;
    .promo-container {
      .promo-image-mobile {
        margin-left: 0;
        width: 100%;
      }
    }
  }

  @include able.mediaQuery(breakpointMD) {
    .promo-container {
      .promo-content {
        width: 60%;
      }
    }
  }
}

.grey-container {
  background-color: #f4f4f4;
}

.pink-container {
  background-color: #FFEFF7;
}

// R18TODO
.page-wrapper {
  margin-top: 60px;
  @include able.mediaQuery(breakpointMD) {
    margin-top: 120px;
    // @include make-col(10);
    // @include make-col-offset(1);
  }

  // @include media-breakpoint-up(xl) {
  //   margin-top: 120px;
  //   @include make-col(10);
  //   @include make-col-offset(1);
  // }

  @include able.mediaQuery(viewportXS_plus_SM) {
    padding: 0 12px;
    // @include make-col(12);
    // @include make-col-offset(0);
  }
}

.clear-div-xs {
  padding: 8px 0;
  width: 100%;
}

.clear-div-s {
  padding: 12px 0;
  width: 100%;
}

.clear-div-m {
  padding: 16px 0;
  width: 100%;
}

.clear-div-l {
  padding: 20px 0;
  width: 100%;
}

.clear-div-xl,
.clear-div-xxl {
  padding: 32px 0;
  width: 100%;
}

.clear-div-xxxl {
  padding: 56px 0;
  width: 100%;
}

.hidden-xs {
  @include able.mediaQuery(viewportXS) {
    display: none;
  }
}

.visible-xs {
  @include able.mediaQuery(breakpointSM) {
    display: none;
  }
}