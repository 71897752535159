.tplus-rewards-search-container {
   .tplus-rewards-searchbox .tplus-rewards-product-tile__link::after {
    position: relative ;
  }
  display: flex;
  .tplus-rewards-searchbox {
    position: relative;
    width: 100%;
  }
  .bold-span {
    font-weight: 700;
  }
  svg {
    width: 24px;
    height: 24px;
    border-radius: 0px;
    position: absolute;
    display: inline-block;
    left: calc(100% - 40px);
    top: 44px;
  }

  #listItem {
    &:hover {
      height: 48px;
      background: rgb(236, 247, 254);
      border-radius: 0px;
    }
    &:focus {
      height: 48px;
      background: rgb(236, 247, 254);
      border-radius: 0px;
    }
  }
}
@media screen and (min-width: 1680px) {
  .tplus-rewards-product-tile__container .able-text-style--Base {
    font: 400 1rem/1.4 Telstra Akkurat, Helvetica Neue, Helvetica, Arial, sans-serif;
  }
  .tplus-rewards-product-tile__container .able-text-style--BaseBig {
    font: 400 1.25rem/1.4 Telstra Akkurat, Helvetica Neue, Helvetica, Arial, sans-serif;
  }
  .tplus-rewards-search-container svg {
    width: 24px;
    height: 24px;
    border-radius: 0px;
    position: absolute;
    display: inline-block;
    left: calc(100% - 40px);
    top: 52px;
  }
}
