@use "@able/web/src" as able with ($include-breakpointLG: false);

.tplus-action-list-container {
  color: blue;
  width: 100%;

  .tplus-action-list-heading {
    font-size: 32px;
    font-weight: bold;
    line-height: 34px;
    margin-left: 12px;
    margin-bottom: 1.1em;
  }

  .tplus-action-list-content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;

    .tplus-action-list-item {
      display: flex;
      width: 50%;
      margin-top: 0;

      &.heading-item {
        &:hover {
          border: none;
        }
      }

      a {
        display: flex;
        flex-direction: column;
        color: able.colour(interactiveForegroundNormal);
        width: 100%;
        text-decoration: none;

        &:hover, &.tplus-action-list-item__selected {
          color: able.colour(interactiveForegroundNormal);

          .tplus-action-list-item-container {
            svg {
              top: -8px;
              use {
                fill: able.colour(interactiveForegroundNormal);
              }
            }

            span.action-item-link-text {
              color: able.colour(interactiveForegroundNormal);
            }
          }
        }

        &:hover .tplus-action-list-border-bottom, &.tplus-action-list-item__selected .tplus-action-list-border-bottom {
          color: able.colour(interactiveForegroundNormal);
          border-bottom: 2px solid able.colour(interactiveForegroundNormal);
          height: 0;
        }

        .tplus-action-list-border-bottom {
          display: flex;
          margin: 14px 12px 0 12px;
          height: 2px;
        }

        .tplus-action-list-item-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          flex-grow: 1;
          width: 100%;
          padding: 0 16px;

          svg {
            top: 0;
            position: relative;
            transition: all 0.35s ease-in-out;
          }

          span.action-item-link-text {
            @include able.TextStyle(Label, Center);

            display: flex;
            justify-content: center;
            text-align: center;
            font-size: 16px;
            font-weight: bold;
            line-height: 22px;
            width: 100%;
          }
        }
      }
    }
  }

  // XS up
  .tplus-action-list-content {
    &.inline-heading {
      .tplus-action-list-item.heading-item {
        display: block;
        width: 100%;
        padding: 0 12px;

        h4.heading {
          margin-top: 0;
          font-size: 32px;
          font-weight: bold;
          line-height: 34px;
          margin-bottom: 1.1em;
        }
      }
    }

    .tplus-action-list-item {
      width: 33%;

      &:nth-of-type(1),
      &:nth-of-type(2),
      &:nth-of-type(3) {
        margin-bottom: 16px;
      }

      a .tplus-action-list-item-container {
        border-right: 1px solid able.colour(dividerEmphasis);
        margin: 8px 0;

        svg {
          margin-top: 0;
        }

        span.action-item-link-text {
          margin-top: 8px;
        }
      }

      &:nth-child(3n) a .tplus-action-list-item-container {
        border-right: 0;
      }
    }

    &.heading-above {
      .tplus-action-list-item {
        &:nth-child(even) a .tplus-action-list-item-container {
          border-right: 1px solid able.colour(dividerEmphasis);
        }

        &:last-child a .tplus-action-list-item-container {
          border-right: 0;
        }
      }
    }
  }
  

  @include able.mediaQuery(breakpointMD) {
    .tplus-action-list-content {
      .tplus-action-list-item {
        a .tplus-action-list-item-container {
          margin: 0;

          svg {
            margin-top: 8px;
          }

          span.action-item-link-text {
            margin: 8px 0 16px 0;
          }
        }
      }
    }
  }

  @include able.mediaQuery(breakpointMD) {
    .tplus-action-list-content {
      &.center-content {
        justify-content: center;
      }

      .tplus-action-list-item {
        width: (100%/6);
        margin-top: 0;

        &:nth-of-type(1),
        &:nth-of-type(2),
        &:nth-of-type(3) {
          margin-bottom: 0;
        }

        &:nth-child(3n) a .tplus-action-list-item-container {
          border-right: 1px solid able.colour(dividerEmphasis);
        }

        &:last-child a .tplus-action-list-item-container {
          border-right: 0;
        }

        @at-root li.#{tplus-action-list-item} {
          &:first-child:nth-last-child(4),
          &:first-child:nth-last-child(4) ~ & {
            width: (100%/4);
          }

          &:first-child:nth-last-child(5),
          &:first-child:nth-last-child(5) ~ & {
            width: (100%/5);
          }
        }
      }
    }
  }
}