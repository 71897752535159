@import 'styles/base.scss';

.inline-loading-spinner {
  display: block;
  text-align: center;
  padding: 60px;
  img {
    width: 48px;
    height: 48px;
  }
  &.small-spinner {
    display: inline;
    padding: 12px;
    font-size: 18px;
    padding-left: 0;
    font-weight: 400;

    img {
      position: relative;
      width: 24px;
      height: 24px;
      top: 6px;
      margin-right: 18px;
    }
  }
}

.plus-loader {
  // 54px header height + 20px (half of loader)
  padding-top: calc(50vh - 74px);
  margin: 0 auto;
  width: 48px;
  text-align: center;

  img {
    width: 48px;
    height: 48px;
  }
}

.loading-phrase {
  display: block;
  text-align: center;
  padding-top: 16px;
}

.loader {
  display: inline-block;
  width: 2.5rem;
  height: 2.5rem;
  border: 0.2rem solid rgba(218, 218, 218, 0.5);
  border-radius: 50%;
  border-top-color: #0062da;
  animation: spin 1.5s ease-in-out infinite;
  -webkit-animation: spin 1.5s ease-in-out infinite;
}

.full-width {
  display: flex;
}

.center {
  justify-content: center;
}
