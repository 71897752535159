@use "@able/web/src" as able;
@import '~styles/base.scss';

.tplus-rewards-product {
  .image-col {
    text-align: center;
  }

  .image-col-placeholder {
    margin: 0 auto;
  }

  .attribute-item-hidden {
    display: none;
  }
  .original-points {
    margin-top: able.spacing(spacing2x);
    text-decoration: line-through;
  }
  .current-points {
    margin-top: able.spacing(spacingHalf);
    &.hot-offer {
      color: #a0148c;
    }
  }

  .bruh {
    max-width: 568px;
    margin: auto;
  }
}
.able-Surface {
  .icon-style {
    use {
      fill: #a0148c;
    }
    margin-right: 8px;
  }
  .promo-text-colour {
    color: #a0148c;
  }
}
.specs-heading {
  width: 1127px;
  height: 45px;
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 0px;
  line-height: 45px;
}
.tplus-tnc {
width: 306px;
height: 20px;
color: rgb(112, 112, 112);
font-size: 14px;
font-family: $font-family;
font-weight: normal;
letter-spacing: 0.18px;
line-height: 20px;
margin-top:-60px;
margin-bottom: 81px;
}
.brand-name {
text-align: left;
margin-bottom: 0px;
}
.description-detail {
  text-align: left;
}
.tplus-rewards-product-container {
  margin: auto;
  max-width: 1180px;
}
.product-tags-section {
  display: flex;
  padding-bottom: 40px;
  padding-top: 10px;
  list-style-type: none;
}
.product-tag {
  @include able.Lozenge(Neutral);
  margin-right: 10px;
}
.tplus-accordion-group li {
  list-style: disc inside;
  padding: 4px 0;
  margin-left: 1rem;
}
.tick-icon-style {
  width: 24px;
  height: 24px;
}

.img-container {
    width: 425px;
    height: 528px;
    margin-left: 88px;
    overflow: hidden;
}

.swiper-slide {
    width: 72px !important;
    height: 72px !important;
    border-radius: 0px !important;
    cursor: pointer;
    margin-bottom: 20px;
}

.highest-points {
  background: rgb(244, 244, 244);
  border-radius: 0px;
  height: 82px;
  width: auto;
}
.highest-points .current-points {
  color: rgb(160, 20, 140);
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0px;
  line-height: 34px;
  padding-left: 24px;
  padding-top: 24px;
}
.payment-method-section {
  padding-bottom: 10px;
  padding-top: 68px;
}
.payment-method-section .pm-desc-label {
  font-size: 20px;
}
.what-you-pay-section {
  padding-bottom: 32px;
}
.what-you-pay-section .label-heading {
  padding-top: 24px;
  padding-bottom: 24px;
  font-size: 24px;
}
.what-you-pay-details {
  background: rgb(244, 244, 244);
  border-radius: 0px;
  height: 127px;
  width: auto;
}
.points-upfront {
  display: inline-flex;
  padding-top: 35px;
  padding-left: 30px;
  padding-bottom: 15px;
}
.money-upfront {
  display: inline-flex;
  padding-left: 30px;
  padding-bottom: 30px;
}
.upfront-label {
  color: rgb(65, 65, 65);
  font-size: 16px;
  letter-spacing: 0.15px;
  line-height: 22px;
}
.points-upfront-value {
padding-left: 230px;
font-size: 32px;
font-weight: bold;
margin-top: -12px;
}
.money-upfront-value {
padding-left: 230px;
font-size: 32px;
font-weight: bold;
margin-top: -12px;
}
.adjust-padding {
padding-left: 205px;
}
.padding-left {
padding-left: 40px;
}
.align-left {
text-align: left;
}

@media (min-width: 1024px) {
.img-carousel-container {
flex-direction: column;
width: 72px;
height: 528px;
margin-top: -388px;
margin-left: -60px;
position: absolute;
}
}

@media (max-width: 360px) {
.what-you-pay-details {
height: 163px !important;
padding-right: 20px !important;
}
}

@media (max-width: 768px) {
.swiper-slide {
width: 70px !important;
height: 60px !important;
border-radius: 0px !important;
margin-right:8px;
}

.carousol-align {
margin-right: auto;
margin-left: auto;
display: block;
}

.img-container {
width: 400px;
height: 370px;
margin-left: -18%;
}

.swiper-image-wrapper {
width: 306px;
height: 310px;
border-radius: 0px;
margin-left: 35px;
}
.highest-points {
background: rgb(244, 244, 244);
border-radius: 0px;
height: 82px;
width: auto;
}
.highest-points .current-points {
color: rgb(160, 20, 140);
font-size: 24px;
font-weight: bold;
letter-spacing: 0px;
line-height: 34px;
padding-left: 24px;
padding-top: 24px;
}
.payment-method-section {
padding-bottom: 10px;
padding-top: 38px;
}
.payment-method-section .pm-desc-label {
font-size: 20px;
}
.what-you-pay-section {
padding-bottom: 32px;
}
.what-you-pay-section .label-heading {
padding-top: 24px;
padding-bottom: 24px;
font-size: 24px;
}
.what-you-pay-details {
background: rgb(244, 244, 244);
border-radius: 0px;
height: 127px;
width: auto;
}
.points-upfront {
display: inline-flex;
padding-top: 35px;
padding-left: 30px;
padding-bottom: 15px;
}
.money-upfront {
display: inline-flex;
padding-left: 30px;
padding-bottom: 30px;
}
.upfront-label {
  color: rgb(65, 65, 65);
  font-size: 16px;
  letter-spacing: 0.15px;
  line-height: 22px;
}
.points-upfront-value {
padding-left: 25px;
font-size: 32px;
font-weight: bold;
margin-top: -7px;
}
.money-upfront-value {
padding-left: 25px;
font-size: 32px;
font-weight: bold;
margin-top: -7px;
}
.adjust-padding {
padding-left: 0px;
}
.product-name-section {
text-align: left;
}
.brand-name {
text-align: left;
width: auto;
height: 28px;
font-size: 20px;
font-weight: bold;
letter-spacing: 0px;
line-height: 28px;
margin-bottom: 0px;
}
.img-carousel-container {
width: 400px;
}
.specs-heading {
width: 304px;
height: 82px;
color: rgb(40, 40, 40);
font-size: 24px;
font-weight: bold;
letter-spacing: 0px;
line-height: 34px;
}
.tplus-tnc {
width: 291px;
height: 24px;
color: rgb(112, 112, 112);
font-size: 16px;
font-family: $font-family;
font-weight: normal;
letter-spacing: 0px;
line-height: 24px;
margin-bottom: 81px;
}
}
