@use "@able/web/src" as able;

.tplus-new-features-dialog-close-container {
  position: absolute;
  right: 8px;
  top: 8px;
}

.tplus-new-feature-dialog-close-button {
  svg {
    use {
      display: flex!important;
      fill: black!important;
    }
  }
}
