@use "@able/web/src" as able with ($include-breakpointLG: false);
@import '~styles/base.scss';

$linktextcolor: #7e7e7e;

$signoutmarginmobile: 8px 15px 1px 1px;

.sidebar {
  width: 100% !important;
  height: 100% !important;
  z-index: 9999 !important;

  border-image-slice: 1 0 0 0;
  border-left: 0;
  border-right: 0;
  background-color: rgb(255, 255, 254);

  @include able.mediaQuery(breakpointMD) {
    width: 420px !important;
  }

  &:not(:focus-within) {
    background-color: rgb(255, 255, 254);
    transition: background-color 0.01s ease !important;
  }
}

.sidebar-content {
  overflow: hidden;

  .close-sidebar-btn {
    position: absolute;
    right: 0px;
    top: 8px;
    background: none;
    border: none;
    &:hover {
      cursor: pointer;
    }

    svg {
      use {
        fill: able.colour(fixedBlack);
      }
    }
  }

  .sidebar-top-border {
    @include able.mediaQuery(viewportXS_plus_SM) {
      border-radius: 0;
      &.GOLD {
        border-top: 6px solid able.colour(telstraPlusTier3);
      }
      &.SILVER {
        border-top: 6px solid able.colour(telstraPlusTier2);
      }
      &.MEMBER, &.BUSINESS {
        border-top: 6px solid able.colour(telstraPlusTier1);
      }
    }
  }

  .mobile-account-details {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    @include able.mediaQuery(breakpointMD) {
      display: none;
    }
  }

  .tier-switch-span {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    background-color: able.colour(boxBackground);
    border-radius: 2rem;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    @include able.mediaQuery(breakpointMD) {
      border-radius: 0;
    }
  }

  .call-us-desc {
    @include able.mediaQuery(viewportXS_plus_SM) {
      display: none;
    }
  }

  .logo {
    display: inline-block;
    margin: 8px 24px;

    img {
      width: 32px;
      height: 26px;
    }

    @include able.mediaQuery(breakpointMD) {
      display: none;

      img {
        display: none;
      }
    }
  }

  .h-bar {
    display: block;
    // height: 48px;
    // padding-left: 1.5rem;
    // padding-right: 1.5rem;
    border-bottom: 1px solid $light-border-color;

    @include able.mediaQuery(breakpointMD) {
      border-bottom: none;
    }

    .points-tier-span {
      &.main {
        padding-top: 4px;
        padding-bottom: 8px;
      }
      &.account {
        padding-left: 0px;
      }
      .side-tier-points {
        padding-right: 4px;
        text-transform: capitalize;
        &.right {
          padding-left: 16px;
          border-left: solid 1px;
        }
        &.left {
          padding-right: 16px;
        }
      }
    }
    .sign-out-btn {
      width: auto;
    }
  }
}
  

  .intro-to-mcac {
    padding: 24px 24px;

    @include able.mediaQuery(breakpointMD) {
      padding: 24px 48px;
    }
  }

  .dismiss-btn {
    position: relative;
    left: 24px;

    @include able.mediaQuery(breakpointMD) {
      position: relative;
      left: 48px;
    }
    &:hover {
      cursor: pointer;
    }
  }

  .membership-list {
    position: relative;
    list-style-type: none;

    li {
      position: relative;
      padding: 24px 0;
      padding-left: 24px;
      width: 100%;

      border-bottom: 0.1px solid $light-border-color;

      @include able.mediaQuery(breakpointMD) {
        padding: 24px 0;
        padding-left: 48px;
        width: 410px;
        height: auto;
      }

      .title {
        margin: 0;
        @include able.mediaQuery(breakpointMD) {
          padding-top: 24px;
        }
      }
      .go-to-btn {
        position: absolute;
        right: 10px;
        top: 8px;

        @include able.mediaQuery(breakpointMD) {
          top: 32px;
        }
        &:hover {
          cursor: pointer;
        }
        .selectWidth {
          width: 30%;
        }
      }

      .tier-information {
        padding: 12px 0;

        @include able.mediaQuery(breakpointMD) {
          padding: 24px 0;
        }

        .able-text-style--BaseBig {
          margin: revert;
        }

        .inline-spinner-sidebar {
          display: inline-block;
          height: 24px;
          width: 24px;
        }

        .membership-tier {
          p {
            display: inline-block;
            padding: 0;
            margin: 0;

            &.MEMBER {
              color: able.colour(telstraPlusTier1);
            }
            &.SILVER {
              color: able.colour(telstraPlusTier2);
            }
            &.GOLD {
              color: able.colour(telstraPlusTier3);
            }
            &.BUSINESS {
              color: able.colour(telstraPlusTier1);
            }
          }
          strong {
            margin-right: 6px;
          }
        }

        .points-value {
          margin: 0 24px;

          strong {
            margin-right: 6px;
        }
      }

      &.active {
        background-color: rgba(230, 230, 230, 0.24);
      }
      &:hover {
        background-color: rgba(230, 230, 230, 0.7);
      }
    }
  }
  .sidebar-info {
    height: auto;
    padding: 24px;

    .info-container {
      padding: 24px;
      background-color: rgba(94, 80, 191, 0.06);
    }
  }
}

// Styles taken from tcom-core-nav
.enrolment-header-large, .not-enrolled-header {
  position: fixed;
  top: 0;
  width: 100vw;
  background-color: $white;
  z-index: 10;
  border-image-slice: 1 0 0 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 0;

  // @include media-breakpoint-up(md) {
  //   @include make-col(12);
  //   @include make-col-offset(0);
  // }
  // Styles taken from tcom-core-nav

  .tcom-bar {
    display: none;
    position: relative;
    top: 0;
    height: 0;
    width: 100vw;
    background-color: $tcom-top-background;
    overflow: hidden;

    @include able.mediaQuery(breakpointMD) {
      display: block;
    }

    a {
      position: relative;
      text-decoration: none;
      margin: 0 12px;
      top: 6px;
      padding: 8px;
      img {
        position: relative;
        top: 4px;
        width: 20px;
        height: 20px;
        margin-right: 18px;
      }
    }

    &.docked {
      height: 44px;
      transition: all 0.3s ease-in-out;
    }

    &.not-docked {
      height: 0;
      opacity: 0;
    }
  }

  // @include make-col(12);
  // @include make-col-offset(0);

  // &.scrolling {
  //   box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
  // }

  .logo {
    display: inline-block;
    width: 30px;
    margin: 16px 0px 8px 14px;

    @include able.mediaQuery(breakpointMD) {
      display: inline-block;
      // width: 64px;
      // margin-left: 7%;

      img {
        width: 36px;
        height: 36px;
      }
    }
  }

  .tplus-text {
    padding: 24px 32px;
  }

  .right-text {
    padding-right: 16px;
    &.border {
      border-right: 1px solid able.colour(controlBackground);
    }
  }

  .left-text {
    padding-right: 4px;
    padding-left: 16px;
    &.tier {
      text-transform: capitalize;
    }
  }

  .tcom-nav-container {
    position: relative;
    transition: box-shadow .2s ease-in-out;
    box-shadow: 0 3px 3px 0 var(--dividerSubtle);

    &.GOLD {
      border-top: 6px solid able.colour(telstraPlusTier3);
    }
    &.SILVER {
      border-top: 6px solid able.colour(telstraPlusTier2);
    }
    &.MEMBER, &.BUSINESS {
      border-top: 6px solid able.colour(telstraPlusTier1);
    }
    &.UNAUTH {
      border-top: 6px solid able.colour(interactiveForegroundNormal);
    }

    &.hidden-xs {
      display: none;
      @include able.mediaQuery(breakpointMD) {
        position: relative;
        /*right: 12px;*/
        display: flex;
        //height: 100%;
        max-width: 100%;
        height: 77px;
      }
    }

    &.visible-xs {
      display: flex;
      flex-direction: column;
      position: relative;
      display: flex;
      max-width: 100%;
      .logo {
        height: 30px;
        color: $text-color-primary;
        text-decoration: none;
        margin: 8px 24px;

        img {
          position: relative;
          top: 4px;
          width: 20px;
          height: 20px;
          margin-right: 18px;
        }
      }

      @include able.mediaQuery(breakpointMD) {
        display: none;
      }
    }

    .tier-container {
      flex: 1;
      height: 48px;
      width: auto;
      position: absolute;
      right: 16px;
      width: auto;

      @include able.mediaQuery(breakpointMD) {
        margin-top: 0px;
        padding: 4px 12px;
      }

      .tier-info {
        position: relative;
        display: flex;
        //justify-content: space-around;
        color: white;
        padding: 14px 0 16px;
        @include able.TextStyle(BaseBig);
        li {
          display: inline;
        }

        .hChevron {
          position: relative;

          svg {
            transform: rotate(180deg);
            fill: white !important;
          }
        }

        span,h2,h1,p {
          display: inline;

          .inline-spinner {
            margin: 0 0px;
            width: 20px;
            height: 20px;
            // position: absolute;
            top: 0px;
            @include able.mediaQuery(viewportXS_plus_SM) {
              width: 16px;
              height: 16px;
            }
          }
        }
      }
      .membership-tier {
        cursor: pointer;
        outline: none;

        button {
          border: 0;
          background: none;
          color: $white;

          margin-left: 8px;
          &:hover {
            cursor: pointer;
          }
        }
      }

      @include able.mediaQuery(breakpointMD) {
        position: absolute;
        right: 0px;
        height: 80px;
        min-width: 470px;
        width: auto;

        .tier-info {
          position: relative;
          margin: 2px 0;
          justify-content: end;

          .able_icon_header{
            height: 32px;
            width: 32px;
            use, path {
              fill: able.colour(hcmWhite);
            }
          }

          .hChevron {
            position: relative;

            svg {
              transform: rotate(180deg);
              fill: white !important;
              margin-top: -3px;
            }
          }
        }
      }
    }
  }
}

.tier-name {
  @include able.mediaQuery(viewportXS) {
    display: none !important;
  }
}

.pts-label {
  @include able.mediaQuery(viewportXS) {
    display: none !important;
  }
}

.pts-end {
  text-transform: lowercase;
  @include able.mediaQuery(breakpointSM) {
    display: none !important;
  }
}

.signout {
  padding-left: 8px;
  background-color: able.colour(iconInvertedSubtle);

  border: none;
  text-decoration: none;
  padding: 24px;
  margin-top: -20px;
  cursor: pointer;
  margin-left: 8px;
  margin-right: 2.2rem;
  padding-bottom: 22px;

  @media screen and (min-width: 1210px) {
    margin-right: 5.5rem;
  }

  &.multicac {
    padding-right: 0px;
  }
  &.not-docked {
    display: none;
  }
}

.offset-div-default {
  height: 107px;
  @include able.mediaQuery(breakpointMD) {
    height: 121px;
  }
}

.offset-div-notification-bar {
  height: 146px;
  @include able.mediaQuery(breakpointMD) {
    height: 70px;
  }
}

.chevron {
  position: relative;

  svg {
    position: relative;
    top: 6px;
    transform: rotate(270deg);
    width: 24px;
    height: 24px;
  }
}

$signoutmargin: 18px 30px 1px 1px;
$signoutmarginmobile: 2px 24px 6px 6px;

.lineEllipsis {
  display: inline;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 320px;
 
  @include able.mediaQuery(viewportXS) {
    max-width: 170px;
  }
  @media only screen and (max-width: 390px) and (min-width: 320px) {
    max-width: 160px;
  }
  @media only screen and (max-width: 330px) {
    max-width: 130px;
  }
  @media only screen and (max-width: 830px) and (min-width: 576px) {
    max-width: 230px;
  }
}

.lineEllipsis-tbar {
  float: left;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}

.breifImg {
  vertical-align: middle;
  margin-right: 8px;
  margin-left: 16px;
  margin-top: -2px;
  width: 24px;
  height: 24px;
  @include able.mediaQuery(breakpointMD) {
    float: left;
    width: 28px;
    height: 28px;
    margin-top: 0;
  }
  
}

.accountNameStyle {
  width: 70%;
  height: 5vh;
  padding-right: 24px;
}

.headerChevron {
  content: '';
  cursor: pointer;
  display: inline-block;
  margin-top: -14px;

  .chevron-icon {
    width: 24px;
    height: 24px;
  }

  @include able.mediaQuery(viewportXS_plus_SM) {
    margin-left: 24px;
    margin-right: 8px;
    position: absolute;
    right: 0;
    left: auto;
    top: 10px;
  }
}

.multicac-btn {
  margin-right: 16px !important;
}

.sidebar-open {
  cursor: pointer;
}

.sidebar-pt {
  margin: 0px;
  padding: 24px;
  padding-left: 48px;
  padding-top: 48px;
}

.tcom-global-header__gradient {
  width: 100vw;
  border-top: 2px solid;
  border-right: 0;
  border-left: 0;
  border-image: linear-gradient(90deg, #0064d2, #5cd6e0 30%, #5e50bf);
  border-image-slice: 1;
}

.imgSize {
  width: 32px;
  height: 25px;
  margin-left: 16px;
  margin-right: 8px;
  margin-top: -3px;
}

.alignItems {
  flex: 1 1 auto;
  text-align: right;
  padding-top: 6px;
  margin-left: 24px !important;
  margin-right: 16px !important;
}

.deskAlignItems {
  flex: 1 1 auto;
  text-align: right;
}
