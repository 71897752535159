@use "@able/web/src" as able;

.tplus-npageheader-container {

  width: 100vw;
  position: relative;
  margin-left: -50vw;
  left: 50%;

  &.noImage {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: able.spacing(spacing2x);
  }

  padding: 0 able.spacing(spacing2x) able.spacing(spacing3x);
  background-color: able.colour(materialBaseTertiary);
  overflow-x: hidden;

  @include able.mediaQuery(breakpointMD) {
    padding: 0 able.spacing(spacing2x) able.spacing(spacing4x);
  }

}

.articleHeader {
  margin-bottom: able.spacing(spacing5x);
}

.tplus-npageheader-container--service-list {
  margin-bottom: 0;
  padding: 0 able.spacing(spacing2x);
}

.tplus-npageheader-spacing {
  margin-bottom:  able.spacing(spacing7x);

  @include able.mediaQuery(breakpointMD) {
    margin-bottom: able.spacing(spacing10x);
  }
}

.tplus-npageheader-spacing--small {
  margin-bottom: able.spacing(spacing4x);

  @include able.mediaQuery(breakpointMD) {
    margin-bottom: able.spacing(spacing5x);
  }
}

.tplus-able__text-body-short {
  @include able.TextStyle(Base);
}
.tplus-npageheader-wrapper__padding { 
  padding-left: 24px;
  @include able.mediaQuery(breakpointMD) {
    padding-left: 76px;
  }
}
.tplus-npageheader-wrapper__title {
  margin: 0 auto;
  @include able.TextStyle(Base);
  h1 {
    padding: 0.5rem 1.5rem;
  }
  width: 1004px;

  @media screen and (min-width: 1210px) {
    width: 1135px;
    h1 {
      padding: 1rem 1.5rem;
    }
  }

  @include able.mediaQuery(viewportSM) {
    h1 {
      padding: 0.5rem 1.25rem;
    }
  }

  @include able.mediaQuery(viewportXS) {
    h1 {
      padding: 0.5rem 0.75rem;
    }
  }

}

.tplus-npageheader-wrapper__background {
  width: 100%;
  display: flex;
  justify-content: center;

  @media screen and (min-width: 1210px) {
    margin: 0 auto;
  }
  
  // padding-left: able.spacing(spacing3x);

  // @include able.mediaQuery(breakpointMD) {
  //   padding-left: able.spacing(spacing5x);
  //   width: 736px;
  // }

  // @include able.mediaQuery(viewportSM) {
  //   width: 1004px;
  //   margin: 0 auto;
  // }
}

.tplus-npageheader-wrapper__background--noImage {
  position: relative;
  // background-color: transparent;
  // width: 100vw;

  .tplus-npageheader-wrapper__title {
    display: flex;
    justify-content: left;
    // background-color: #f0f0f0;
    @include able.mediaQuery(viewportXS_plus_SM) {
      width: 100%;
    }
  }

  .tplus-npageheader-wrapper__padding {
    display: none;
  }

  @media screen and (min-width: 1210px) {
    margin: 0;

    .tplus-npageheader-wrapper__title {
      width: 1135px;
      margin: 0 auto;
    }
    .tplus-npageheader-wrapper__padding {
      display: block;
    }
  }
}

.tplus-npageheader-wrapper__background--noImage::after {
  content: '';
  position: absolute;
  background-color: able.colour(interactiveBackgroundNormal);;
  height: 100%;
  right: 50%;
  left: 0;
  z-index: -1;
}

.tplus-npageheader-wrapper__title--noImage::after {
  content: "";
  position: absolute;
  // background-color: #f0f0f0;
  height: 100%;
  right: 0;
  left: 0;
  z-index: -2;
}

.tplus-able__text-heading-a {
  @include able.TextStyle(HeadingA);
}

.tplus-npageheader-wrapper__title--title {
  @include able.TextStyle(HeadingA);
}

.tplus-npageheader-wrapper__title--noImage.tplus-npageheader-wrapper__title--title::after {
  height: 100%;
}

.tplus-npageheader-wrapper__title--noImage {
  padding: 0;
  display: flex;
  flex-direction: row;
  // background-color: #f0f0f0;
  margin-left: 0;

  // @include able.mediaQuery(breakpointMD) {
  //   margin-left: -64px;
  // }
  // @media screen and (min-width:1210px) {
  //   margin-left: -150px;
  // }
  // @media screen and (min-width:1360px) {
  //   margin-left: -250px;
  // }

  .tplus-npageheader-title__text {
    padding-top: able.spacing(spacing2x);
    padding-bottom: able.spacing(spacing1x);

    @include able.mediaQuery(breakpointMD) {
      width: 67%;
    }
  }

  .tplus-npageheader-title__text.tplus-able__text-heading-a {
    @include able.TextStyle(HeadingA);
    padding-bottom: 1rem;
  }

  .tplus-npageheader-title__text.tplus-able__text-heading-d {
    @include able.TextStyle(HeadingD);
  }
}

.tplus-npageheader-title__noimage {
  padding-left: 24px;
  margin-right: 24px;
  // margin-left: -64px;

  @include able.mediaQuery(breakpointSM) {
    padding-left: 28px;
    margin-right: 24px;
  }

  @include able.mediaQuery(breakpointMD) {
    margin-right: 40px;
  //   padding-left: 76px;
  }

}

// .tplus-npageheader-gradient {
//   padding-left: 24px;


//   @include able.mediaQuery(breakpointMD) {
//     padding-left: 76px;
//   }

//   .tplus-npageheader-background-image {
//     background-size: cover;
//   }
  
// }

.tplus-npageheader-background-image {
  // background: url("./../../assets/images/pageheader-telstra-plus-small-24x1000.png") no-repeat;

  // @include able.mediaQuery(breakpointMD) {
  //   background: url("./../../assets/images/pageheader-telstra-plus-large-75x500.png") no-repeat;
  // }
}



.tplus-npageheader-wrapper {
  display: flex;
  // flex-direction: row;
  // width: 100%;
  // background: #f9f9f9;
  justify-content: center;
  // margin-left: 1.5rem;

  @include able.mediaQuery(viewportSM) {
    margin-left: 8px;
    margin-right: 8px;
  }

  @include able.mediaQuery(breakpointMD) {
    margin-left: 8px;
  }
  @media screen and (min-width: 1210px) {
    margin: 0 auto;
    width: 1135px;
  }
}

.tplus-npageheader-wrapper--noImage {
  justify-content: flex-start;
  // background: white;
  width: 100%;

  @include able.mediaQuery(breakpointSM) {
    // justify-content: center;
    // width: 1004px;
  }

  @media screen and (min-width: 1210px) {
    // justify-content: center;
    width: 1135px;
  }
}

.tplus-npageheader-wrapper--short {
  @include able.mediaQuery(breakpointMD) {
    max-height: 400px;
  }
}

.tplus-npageheader-wrapper__container {
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
  border-radius: 8px 90px 8px 8px;

  @include able.mediaQuery(breakpointMD) {
    flex-direction: row;
    justify-content: flex-start;
    max-height: 500px;
    overflow: hidden;
    background-color: able.colour(materialBasePrimary);
  }

  @include able.mediaQuery(viewportSM) {
    align-self: center;
  }

  @include able.mediaQuery(breakpointSM) {
    max-height: 540px;
    border-radius: 8px;
    width: 1004px;
  }

  @media screen and (min-width: 1210px) {
    width: 1245px;
  }
}

.tplus-npageheader-wrapper__container--noImage {
  border-radius: 0;
  background-color: unset;
  justify-content: flex-start;
  width: 100%;

  @media screen and (min-width: 1210px) {
    width: 1135px;
  }

  @include able.mediaQuery(breakpointSM) {
    width: 1004px;
  }
}

.tplus-npageheader-wrapper__content {
  // background-color: var(--materialBasePrimary);
  
  padding: 0 0.7rem;
  
  @include able.mediaQuery(breakpointMD) {
    max-height: 404px;
    overflow: hidden;
    padding: 0 1rem;
    width: 592px;
    max-width: 592px;
    align-self: center;
  }

  @media screen and (min-width: 1210px) {
    padding: 0 1.5rem;
  }
}

.tplus-npageheader-wrapper__content--short {
  @include able.mediaQuery(breakpointMD) {
    max-height: 336px;
    overflow: hidden;
    padding: 0 1.5rem;
  }
}

.tplus-npageheader-sectionNav {
  // margin-top: 24px;
  background-color: able.colour(materialBaseTertiary);
  padding-top: able.spacing(spacing3x);
  padding-bottom: able.spacing(spacingHalf);
  overflow-x: hidden;

  @include able.mediaQuery(breakpointMD) {
    padding-top: able.spacing(spacing4x);
    padding-bottom: able.spacing(spacingHalf);
  }
}

.tplus-npageheader-spacing-servicelist {
  margin-bottom: able.spacing(spacing7x);

  @include able.mediaQuery(breakpointMD) {
    margin-bottom: able.spacing(spacing10x);
  }
}

.tplus-npageheader-spacing--small-servicelist {
  margin-bottom: able.spacing(spacing4x);

  @include able.mediaQuery(breakpointMD) {
    margin-bottom: able.spacing(spacing5x);
  }
}

.tplus-npageheader-spacing--none-servicelist {
  margin-bottom: 0;
}


.tplus-npageheader-sectionNav--brandmessage {
  margin-top: 24px;

  @include able.mediaQuery(breakpointLG) {
    margin-top: 48px;
  }
}

.tplus-npageheader-wrapper__content--noImage {
  width: 100%;
  background-color: able.colour(materialBaseTertiary);
  padding-top: 0px;
  // padding: 24px 24px 24px 48px;

  @include able.mediaQuery(breakpointMD) {
    // padding: 24px 24px 24px 93px;
    max-width: -webkit-fill-available;
  }
  @media screen and (min-width: 1210px) {
    // padding: 24px 24px 24px 79px;
    max-width: -webkit-fill-available;
  }
}

.tplus-npageheader-content__headline {
  margin-bottom: 16px;

  @include able.TextStyle(HeadingA);
}

.tplus-able__text-heading-d {
  @include able.TextStyle(HeadingD);
}

.tplus-npageheader-content__headline--xlarge {
  @include able.TextStyle(HeadingDisplay);

  @include able.mediaQuery(breakpointLG) {
    @include able.TextStyle(HeadingA);

    margin-bottom: 16px;
  }
}

.tplus-npageheader-content__description {
  margin-bottom: 16px;
  @include able.TextStyle(BaseBig);
}

.tplus-npageheader-content__tnc {
  margin-bottom: 16px;

  @include able.TextStyle(FinePrint);
}

.tplus-npageheader-content__buttons {
  display: flex;
  flex-direction: column;
  margin-top: 16px;

  @include able.mediaQuery(breakpointSM) {
    flex-direction: row;
  }

  .tplus-npageheader-content-cta {
    a.tplus-npageheader-content-cta--primary,
    a.tplus-npageheader-content-cta--primary:link,
    a.tplus-npageheader-content-cta--primary:visited {
      color: able.colour(interactiveInverseForegroundNormal);
    }
  }
}

.tplus-npageheader-content-cta-primary-secondary-wrapper {
  display: flex;
  flex-direction: column;

  @include able.mediaQuery(breakpointMD) {
    flex-direction: row;
  }
}

.tplus-npageheader-content-cta-tertiary-wrapper {
  margin-top: 16px;
}

.tplus-npageheader-content__buttons--noImage {
  @include able.mediaQuery(breakpointMD) {
    flex-direction: row;

    .tplus-npageheader-content-cta--primary,
    .tplus-npageheader-content-cta--secondary,
    .tplus-npageheader-content-cta--tertiary {
      margin: 0 24px 0 0;
    }
  }
}

.tplus-npageheader-content__buttons--tertiary {
  flex-direction: column;
}

.tplus-npageheader-content-cta--primary,
.tplus-npageheader-content-cta--secondary,
.tplus-npageheader-content-cta--tertiary {
  margin: 0 0 24px 0;

  @include able.mediaQuery(breakpointMD) {
    margin: 0 24px 0 0;
  }
}

.tplus-npageheader-content-cta--primary:last-child,
.tplus-npageheader-content-cta--secondary:last-child,
.tplus-npageheader-content-cta--tertiary:last-child {
  margin-bottom: 0;
}

// .tplus-npageheader-content-cta--primary {
//   @include able.ActionButton(HighEmphasis);
// }

// .tplus-npageheader-content-cta--secondary {
//   @include able.ActionButton(MediumEmphasis);
// }

// .tplus-npageheader-content-cta--tertiary {
//   @include able.ActionButton(LowEmphasis);
//   fill: currentColor;
//   padding-left: 0;
// }

.tplus-npageheader-content__imageLogo {
  margin-bottom: 16px;
}

.tplus-npageheader-imageLogo__desktop {
  display: none;
  max-height: 34px;

  @include able.mediaQuery(breakpointMD) {
    display: block;
  }
}

.tplus-npageheader-imageLogo__mobile {
  display: block;
  max-height: 34px;

  @include able.mediaQuery(breakpointMD) {
    display: none;
  }
}

.tplus-npageheader-mainImage {
  max-height: 575px;
  overflow: hidden;
  width: 100%;

  @include able.mediaQuery(viewportSM) {
    max-height: 434px;
    // display: flex;
    // justify-content: center;
  }

  // @include able.mediaQuery(breakpointSM) {
  //   width: 524px;
  // }

  @include able.mediaQuery(breakpointMD) {
    width: 692px;
    margin: -6px;
  }

  img {
    width: 100%;
    border-radius: 8px 80px 0 0;

    @include able.mediaQuery(breakpointMD) {
      border-radius: 120px 0 0 0;
      margin-top: 40px;
    }
  }

  &.tplus-npageheader-image-corner--bottom {
    img {
      @include able.mediaQuery(breakpointMD) {
        border-radius: 0 0 0 120px;
        margin-bottom: 40px;
        margin-top: -70px;
      }
    }
  }

}

.tplus-npageheader-animation--desktop {
  display: none;

  @include able.mediaQuery(breakpointLG) {
    display: block;
  }
}

.tplus-npageheader-animation--landscape {
  display: none;

  @include able.mediaQuery(viewportMD) {
    display: block;
  }
}

.tplus-npageheader-animation--portrait {
  display: none;

  @include able.mediaQuery(viewportSM) {
    display: block;
  }
}

.tplus-npageheader-animation--mobile {
  display: none;

  @include able.mediaQuery(viewportXS) {
    display: block;
  }
}

.tplus-npageheader-brandmessage {
  background: #ffeff7;
  width: 100%;
  margin-bottom: 8px;
  padding: 0 48px 0 48px;
  display: flex;
  justify-content: center;
  @include able.TextStyle(FinePrint);
}

.tplus-npageheader-brandmessage--off {
  // border-bottom: 1px solid #f4f4f4;
  width: 100%;
}

.tplus-npageheader-brandmessage__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 0;

  @include able.mediaQuery(breakpointMD) {
    max-width: 656px;
  }

  @include able.mediaQuery(breakpointLG) {
    max-width: 936px;
  }
}

.tplus-npageheader-brandmessage__text {
  text-align: initial;
  @include able.TextStyle(FinePrint);
}

.tplus-npageheader-brandmessage__link {
  @include able.MessageInline (Default);
}

.tplus-npageheader-brandmessage__icon {
  margin-right: 8px;
  min-width: 32px;
  align-self: baseline;
}

.tplus-npageheader-timer--hidden {
  display: none;
}


.tplus-able__palette-default {
  .tplus-npageheader-wrapper__container {
    background-color: able.color(materialBasePrimary);
  }
  .tplus-npageheader-content__headline,
  .tplus-npageheader-content__headline--xlarge {
    color: able.color(textBrandTertiary);
  }
}

// Able Palettes
.tplus-able__palette-1 {
  .tplus-npageheader-wrapper__container {
    background-color: able.color(materialBaseBrandTertiary);
  }
  .tplus-npageheader-content__headline {
    color: able.color(textInvertedBase);
  }
  .tplus-npageheader-content-cta--secondary:hover,
  .tplus-npageheader-content-cta--secondary:visited {
    a {
      background-color: able.color(destructiveBackgroundActive);
    }
  }

  .tplus-npageheader-content-cta--tertiary:hover,
  .tplus-npageheader-content-cta--tertiary:visited {
    a {
      color: able.color(interactiveForegroundActive);
    }
  }

  a {
    color: able.colour(interactiveForegroundActive);
  }
  svg {
    path, rect {
      fill: able.colour(interactiveForegroundActive) !important;
    }
  }

  .tplus-npageheader-content-cta--primary {
    a {
        background-color: able.colour(interactiveForegroundActive);
        color: able.colour(interactiveBackgroundNormal);
    }
  }

  .tplus-npageheader-content-cta--secondary {
    a {
        background-color: able.colour(fixedTransparent);
        color: able.colour(interactiveForegroundActive);
        border: 0.125rem solid able.colour(interactiveForegroundActive);
    }
  }

  .tplus-npageheader-content__description,
  .tplus-npageheader-content__tnc,
  .tplus-countdown-timer__description {
      color: able.color(textBase);
  }
}

.tplus-able__palette-3 {
  .tplus-npageheader-wrapper__container {
    background-color: able.color(materialBaseBrandPrimary);
  }
  .tplus-npageheader-content__headline {
    color: able.color(textBrandSecondary);
  }

  .tplus-npageheader-content-cta--secondary:hover,
  .tplus-npageheader-content-cta--secondary:visited{
    a {
      background-color: able.color(destructiveBackgroundActive);
    }
  }

  .tplus-npageheader-content-cta--tertiary:hover,
  .tplus-npageheader-content-cta--tertiary:visited {
    a {
      color: able.color(interactiveBackgroundNormal);
    }
  }

  a {
    color: able.colour(interactiveBackgroundNormal);
  }
  svg {
    path, rect {
      fill: able.colour(interactiveBackgroundNormal) !important;
    }
  }

  .tplus-npageheader-content-cta--primary {
    a {
        background-color: able.colour(interactiveBackgroundNormal);
        color: able.colour(interactiveForegroundActive);
    }
  }

  .tplus-npageheader-content-cta--secondary {
    a {
        background-color: able.colour(fixedTransparent);
        color: able.colour(interactiveBackgroundNormal);
        border: 0.125rem solid able.colour(interactiveBackgroundNormal);
    }
  }

  .tplus-npageheader-content__description,
  .tplus-npageheader-content__tnc,
  .tplus-countdown-timer__description {
    color:  able.color(textInvertedBase);
  }
}


  .tplus-able__palette-5 {
  .tplus-npageheader-wrapper__container {
    background-color: able.color(materialBaseBrandQuaternary);
  }
  .tplus-npageheader-content__headline {
    color: able.color(textBrandTertiary);
  }
  .tplus-npageheader-content-cta--secondary:hover,
  .tplus-npageheader-content-cta--secondary:visited {
    a {
      background-color: able.color(destructiveBackgroundActive);
    }
  }

  .tplus-npageheader-content-cta--tertiary:hover,
  .tplus-npageheader-content-cta--tertiary:visited {
    a {
      color: able.color(interactiveBackgroundNormal);
    }
  }

  a {
    color: able.colour(interactiveBackgroundNormal);
  }
  svg {
    path, rect {
      fill: able.colour(interactiveBackgroundNormal) !important;
    }
  }

  .tplus-npageheader-content-cta--primary {
    a {
        background-color: able.colour(interactiveBackgroundNormal);
        color: able.colour(interactiveForegroundActive);
    }
  }

  .tplus-npageheader-content-cta--secondary {
    a {
        background-color: able.colour(fixedTransparent);
        color: able.colour(interactiveBackgroundNormal);
        border: 0.125rem solid able.colour(interactiveBackgroundNormal);
    }
  }

  .tplus-npageheader-content__description,
  .tplus-npageheader-content__tnc,
  .tplus-countdown-timer__description {
      color: able.color(textInvertedBase);
  }
}