@use "@able/web/src" as able;

.tplus-rewards-variations-selector {
    display: flex;
    flex-wrap: wrap;

    [class^="tplus-rewards-variations-selector-button"] {
        display: "inline-block";
        border-radius: 4px;
        border: 1px solid able.colour(borderNormal);
        cursor: pointer;

        .tplus-rewards-variations-value {
            font-size: 16px;
            font-weight: bold;
            letter-spacing: 0px;
            line-height: 22px;
            color: rgb(40, 40, 40);
        }

        &:hover {
            border: 1px solid able.colour(borderActive);
            border-radius: 4px;
            background-color: able.colour(interactiveBackgroundHover);
            font-weight: bold;

            .tplus-rewards-variations-value {
                color: #001e82;
            }
        }

        &:not(:hover) {
            background-color: #ffffff;
        }

        &:focus-visible {
            border: 2px solid able.colour(borderActive);
            background-color: able.colour(interactiveBackgroundActive);
            outline: 2px solid #b2e0fd;
        }

        &:active {
            border: 1px solid able.colour(borderActive);
            background-color: able.colour(interactiveBackgroundActive);

            .tplus-rewards-variations-value {
                color: #001e82;
            }
        }
    }

    .tplus-rewards-variations-selector-button-selected {
        border: 2px solid able.colour(borderActive);

        &:hover {
            border: 2px solid able.colour(borderActive);
        }
    }
}

.tplus-rewards-variations {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}