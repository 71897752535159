@use "@able/web/src" as able;

/********************************
    Brand Colours
*********************************/
$primaryColour: able.color(telstraPlusTier1);

$tpluspink: #ff2896;

$member-tier-business: able.color(telstraPlusTier1);
$member-tier-gold: able.color(telstraPlusTier3);
$member-tier-silver: able.color(telstraPlusTier2);
$member-tier-normal: $primaryColour;
$member-tier-business: $primaryColour;

$white: #ffffff;
$almost-white: #f2f2f2;

// Top Border
$headerlinegradiant: linear-gradient(
  90deg,
  #ed28be 0%,
  #a788be 55.51%,
  #8797b6 100%
);
$headedtop: 2px solid transparent;

/********************************
    Typography Colours
*********************************/
$text-color-primary: #414141;
$text-color-secondary: #757575;
$text-color-secondary-accessible: #555;

/********************************
    Element Colours
*********************************/
$light-border-color: #d8d8d8;
$border-color: #d2d2d2;
$tcom-top-background: #fbfbfb;
$table-background-color: #f4f4f4;
$dark-border-color: #757575;
