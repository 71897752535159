@use "@able/web/src" as able;

.tcom-table {
  border-collapse: collapse;
  @include able.mediaQuery(viewportXS) {
    display:none;
  }
}

.tcom-table-columnHeader {
  border-collapse: collapse;
}

.tcom-table--extracolumns {
  // @include media-breakpoint-down(lg) {
  //   display: none;
  // }
}

.tcom-table-heading {
  // @extend %tcom-able__text-big-body-short;

  margin-bottom: 24px;
}

.tcom-table-header {
  background-color: able.colour(materialBaseGrey);
  border: 1px solid;
  border-color: #f4f4f4;
  border-bottom: 4px solid #282828;
}

.tcom-table-header--alternate {
  background-color: unset;
  border: unset;
  border-bottom: 4px solid #282828;
}

.tcom-table-header-cell {
  text-align: left;
  vertical-align: top;
  padding: 16px 16px 24px;

  ul,
  ol {
    margin: 0;
    padding-left: 16px;
  }
}

.tcom-table-body--zebra {
  tr:nth-child(even) {
    background-color: able.colour(materialBaseGrey);
  }
}

.tcom-table-body--alternate tr:nth-child(1) {
  background-color: able.colour(materialBaseGrey);
}

.tcom-table-body--alternate.tcom-table-body--zebra {
  tr:nth-child(even) {
    background-color: unset;
  }

  tr:nth-child(odd) {
    background-color: able.colour(materialBaseGrey);
  }
}

.tcom-table-body-row {
  padding: 16px 0;
  border: 1px solid;
  border-color: #ddd white;

  th {
    text-align: left;

    > p {
      margin: 0;
    }
  }

  td > p {
    margin: 0;
  }
}

.tcom-table-body-row__cell {

  padding: 16px 16px;
  vertical-align: top;

  ul,
  ol {
    margin: 0;
    padding-left: 16px;
  }
}

.tcom-table-terms {
  margin-top: 8px;
  display: block;
}

.tcom-table-mobile {
  margin: 0;
  padding: 0;
}

.tcom-table-mobile__container {
  @include able.mediaQuery(breakpointSM) {
    display: none;
  }
}

.tcom-table-mobile--extracolumns {
  // @include media-breakpoint-up(md) {
  //   display: block;
  // }

  // @include media-breakpoint-up(xl) {
  //   display: none;
  // }
}

.tcom-table-mobile__dt {
  display: flex;
  flex-direction: column;
  padding: 16px 8px;
  border-bottom: 2px solid #282828;
  background-color: able.colour(materialBaseGrey);
}

.tcom-table-mobile__dt--header {}

.tcom-table-mobile__dt--data {}

.tcom-table-mobile__dd {
  margin: 0;
  border: 1px solid;
  border-color: #ddd white;
  display: flex;
}

.tcom-table-mobile__dd--header {
  padding: 16px 8px;
  width: 50%;
  min-width: 105px;
}

.tcom-table-mobile__dd--data {
  padding: 16px 8px;
  width: 50%;
  min-width: 105px;
}

.tcom-table-mobile__dt--alternate {
  background-color: unset;
}

.tcom-table-mobile__hideImage {
  // @include media-breakpoint-down(sm) {
  //   display: none;
  // }
}

.tcom-table-mobile__dd--headerAlternate {
  background-color: able.colour(materialBaseGrey);
}

.tcom-table-mobile-columnHeader {}

.tcom-table-mobile-columnHeader__topborder {
  border-top: 2px solid #282828;
}

.tcom-table-mobile-columnHeader__bottomborder {
  border-bottom: 2px solid #282828;
}

.tcom-table-columnHeader-cell {}

.tcom-table-row-header {}

.tcom-table-column-row-header {
  background-color: able.colour(materialBaseGrey);
  border-right: 1px solid #282828;
}

.tcom-table-body-row__cell--background {
  background-color: able.colour(materialBaseGrey);
  padding: 16px 16px 24px;
  vertical-align: top;

  ul,
  ol {
    margin: 0;
    padding-left: 16px;
  }
}

.tcom-table-header-first__col {
  background-color: able.colour(materialBaseGrey);
  border-top: 2px solid #282828;

  // @include media-breakpoint-up(md) {
  //   border-top: 4px solid #282828;
  // }
}

.tcom-table-header-second__col {
  // @extend %tcom-able__text-body-short;

  border-top: 2px solid #282828;

  // @include media-breakpoint-up(md) {
  //   border-top: 4px solid #282828;
  // }
}

.tcom-table-hide-cells {
  display: none;
}

.tcom-table-header-background__unset {
  background-color: unset;
}

.tcom-table-cell__sideborder {
  border-right: 1px solid #ddd;
}

.tcom-table-mobile__dd--data,
.tcom-table-mobile__dt--header,
.tcom-table-mobile__dd--header,
.tcom-table-mobile__dt--data {
  > p {
    margin: 0;
    display: inline;
  }

  ul,
  ol {
    margin: 0;
    padding-left: 8px;
  }
}