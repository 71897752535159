@use "@able/web/src" as able;

.carousel-container-box {
    &_button {
        text-align: center;
    }
    .carousel-container-box_img_link {
        display: block;
    }
}

.multicarousel-container{
    
    @include able.mediaQuery(breakpointMD) {
        margin-left: -20px;
    }

    @include able.mediaQuery(viewportSM) {
        margin-left: -40px;
        margin-right: -40px;

        ul {
            padding-left: 24px;
    
            &.isLastSlide {
                padding-left: 0;
            }
        }
    
        &.isLastSlide {
            left: -24px;
        }
    } 

    @include able.mediaQuery(viewportXS) {
        margin-left: -32px;
        margin-right: -40px;

        ul {
            padding-left: 24px;
    
            &.isLastSlide {
                padding-left: 0;
            }
        }
    
        &.isLastSlide {
            left: -24px;
        }
    } 

    .carousel-presale-date{
        b {
            font-weight: normal;
        }
    }

    .carousel-wrapper-name {
        font-size: 14px;
    }

    .carousel-container-box_img_container {
        position: relative;
        border-radius: 0.5rem;
        padding-bottom: 75%;
        overflow: hidden;
        width: 100%;
    
        img {
            border-radius: 0.5rem;
            vertical-align: top;
            object-fit: cover;
            position: absolute;
            transform: scale(1);
            height: 100%;
            width: 100%;
        }
    }
    
    .react-multiple-carousel__arrow {
        min-width: 44px;
        min-height: 44px;
        border-radius: 32px;
        background-color: able.colour(interactiveInverseBackgroundNormal);
        box-shadow: 0px 4px 4px 1px rgba(0, 4, 10, 0.16),
                    0px 3px 14px 2px rgba(0, 4, 10, 0.04),
                    0px 5px 5px -3px rgba(0, 4, 10, 0.2);
        z-index: 1;
        border: 0;
        outline: 0;

        display: none;

        &.show-arrow {
            display: block;
        }    
    
        &--right {
            @include able.mediaQuery(breakpointMD) {
                right: calc(0.6% - 2px);
            }
            @include able.mediaQuery(viewportXS_plus_SM) {
                right: 32px;
            }
            @include able.mediaQuery(viewportXS) {
                right: 24px;
            }
            @media (max-width: 480px) {
                right: 12px;
            }
        }
    
        &--left {
            @include able.mediaQuery(breakpointMD) {
                left: calc(0.6% - 2px);
            }
            @include able.mediaQuery(viewportSM) {
                left: 20px;
            }
            @include able.mediaQuery(viewportXS) {
                left: 12px
            }
        }
    
        &--right::before, &--left::before {
            color: #F5F5F5;
            font-weight: bolder;
            font-size: 14px;
        }
    

        &:hover {
            background-color: able.colour(interactiveForegroundActive);
            outline: 0;
            border: 0;
        }

        &:active {
            background-color: able.colour(interactiveForegroundActive);
            border: 0;
            outline: 0;
        }
        
    }
    
    &_stack {
        @include able.mediaQuery(viewportXS_plus_SM) {
            margin-left: 0;
            margin-right: 0;
        }
    }

    &_nowshowing {

        .carousel-container-box_img_container {
            padding-bottom: 150%;
        }

    }


    &_transform1 {
        .react-multi-carousel-track {
            -o-transform: none !important;
            -moz-transform: none !important;
            -ms-transform: none !important;
            -webkit-transform: none !important;
            transform: none !important;
        }
    }

    &_transform2 {
        .react-multi-carousel-track {
            @media (min-width: 480px) {
                -o-transform: none !important;
                -moz-transform: none !important;
                -ms-transform: none !important;
                -webkit-transform: none !important;
                transform: none !important;
            }
        }
    }
    
    .carousel-item-padding-40-px {
        @include able.mediaQuery(breakpointMD) {
            padding: 0 20px;
        }
    
        @include able.mediaQuery(viewportSM) {
            padding: 0 16px;
        } 
    
        @include able.mediaQuery(viewportXS) {
            padding: 0 8px;
        } 
    
    }
    

    &.isLastSlide {
        @include able.mediaQuery(viewportXS_plus_SM) {
            .react-multiple-carousel__arrow {
                &--left {
                    margin-left: 24px;
                }
            }
        }
    }
    
}

.carousel-dot {
    padding-right: 8px;
    padding-top: 2px;

    div {
       font-size: 0;
       width: 8px;
       height: 8px;
       border-radius: 4px;
       background: #8A8A8A;
       padding: 3px;

        &:focus {
            // background-color: #080808;
            outline: none;
        }
    }

    &--active {
        padding-right: 8px;
        div {
            font-size: 0;
            background: #080808;
            height: 12px;
            width: 12px;
            border-radius: 6px;
            padding: 5px;
       }
   }
}

.react-multi-carousel-dot-list{
    position: relative;
}


.tplus-jego-page {
    overflow-x: hidden;
}
