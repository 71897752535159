@use "@able/web/src" as able with ($include-breakpointLG: false);
@import '~styles/base.scss';


.sidebar {
  position: fixed !important;
}

.explore__spinner {
  width: 48px;
  height: 48px;
  animation: explore__spinner__rotate 1.4s linear infinite;
}

.explore__spinner__position{
  position: absolute;
}

.explore__spinner__text {
  width: fit-content;
}




.explore__spinner__path {
  stroke-dasharray: 187;
  stroke-dashoffset: 0;
  transform-origin: center
}




 .explore__spinner__path {
  animation: explore__spinner__dash 1.4s ease-in-out infinite,explore__spinner__colors__theme__default 1.4s ease-in-out infinite
}


@keyframes explore__spinner__colors__theme__default {
  0% {
      stroke: #0d54ff;
  }



  60% {
      stroke: #6ccbfe;
  }



  to {
      stroke: #fff;
  }
}


@keyframes explore__spinner__rotate {
  0% {
      transform: rotate(0deg)
  }



  to {
      transform: rotate(1turn)
  }
}



@keyframes explore__spinner__dash {
  0% {
      stroke-dashoffset: 187;
      transform: rotate(0deg)
  }



  50% {
      stroke-dashoffset: 46;
      transform: rotate(135deg)
  }



  to {
      stroke-dashoffset: 187;
      transform: rotate(1turn)
  }
  
}
.able-text-style{
  &.opt-validation-error{
    color: able.colour(destructiveForegroundNormal);
  margin-top: 0.25rem;
  margin-left: -4px;
  position: relative;
  }
}