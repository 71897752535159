@use "@able/web/src" as able with ($include-breakpointLG: false);

@import '~styles/base.scss';

.movie-session-container {
  width: 100%;

  .cinema-summary {
    color: #414141;
    letter-spacing: -0.2px;
    margin-top: 20px;
    margin-bottom: 10px;
    width: 70%;
  }
  .session-list {
    display: flex;
    flex-wrap: wrap;
    .session {
      width: calc(50% - 11px);
      height: 90px;
      padding-top: 24px;
      border-bottom: 1px solid rgb(216, 210, 210);
      cursor: pointer;
      &:nth-child(2n+1) {
        margin-right: 22px;
      }

      .session-time-wrapper {
        display: flex;
        justify-content: space-between;
        margin-bottom: 5px;
        .session-time {
          // color: #414141;
          // font-size: 16px;
          font-weight: bold;
          letter-spacing: -0.1px;
          line-height: 20px;
        }
        button {
          padding: 0;
        }
      }
    }
    .screen-type {
      font-weight: 100;
      letter-spacing: -0.1px;
    }
  }
}

// mobile view
@include able.mediaQuery(viewportXS) {
  .movie-session-container {
    .cinema-summary {
      width: 100%;
    }
    .session-list {
      .session {
        width: 100%;
        margin-right: 0 !important;
      }
    }
  }
  
}