$colorback: #fff;
$colorbackhigh: lighten($colorback, 20%);
$colortext: #fff;
$colortextlow: darken($colortext, 50%);
$colorprimary: #a0148c;

$checkboxsize: 20px;
$switchsize: $checkboxsize;

$checkboxbordersize: 2px;
$checkboxbordercolor: $colorprimary;
$checkboxbordercolordisabled: $colorbackhigh;

$checkboxcheckmarksize: $checkboxsize - (2 * $checkboxbordersize);
$checkboxcheckmarkcolor: $colorprimary;
$checkboxcheckmarkcolordisabled: $colorbackhigh;

$checkboxlabelcolor: $colortext;
$checkboxlabelcolordisabled: $colortextlow;

// CHECKBOXES

.cus-checkbox {
  display: flex;
  align-items: center;
  //   padding: .5rem;

  &label {
    flex-shrink: 0;
    padding: 16px 16px;
    color: $checkboxlabelcolor;
    cursor: pointer;
  }

  &input {
    position: relative;
    flex-shrink: 0;
    width: $checkboxsize;
    height: $checkboxsize;
    appearance: none;
    // outline: none; // TODO: manage :focus
    background: $colorback;
    cursor: pointer;
    border: $checkboxbordersize solid $checkboxbordercolor;

    &::before {
      content: ' ';
      position: absolute;
      top: 50%;
      right: 50%;
      bottom: 50%;
      left: 50%;
      transition: all 0.1s;
      background: $checkboxcheckmarkcolor;

      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    &:checked {
      &::before {
        top: $checkboxbordersize;
        right: $checkboxbordersize;
        bottom: $checkboxbordersize;
        left: $checkboxbordersize;
      }
    }

    &:disabled {
      border-color: $checkboxbordercolordisabled;
      cursor: default;

      &::before {
        background-color: $checkboxcheckmarkcolordisabled;
      }

      + .cus-checkboxlabel {
        color: $checkboxlabelcolordisabled;
        cursor: default;
      }
    }
  }

  &tick {
    & input {
      width: 26px;
      height: 25px;
      border: 2px solid #7e7e7e;

      &:before {
        top: 50%;
        right: 50%;
        bottom: 50%;
        left: 50%;
        transition: none;
        background: $checkboxcheckmarkcolor;
        border: solid #fff;
        border-width: 0 2px 2px 0;
      }
      &:checked {
        border: solid $checkboxbordercolor;
        &::before {
          top: 1px;
          bottom: 0;
          left: 7px;
          width: 7px;
          height: 14px;
        }
        background: $checkboxcheckmarkcolor;
      }
    }
  }
  &has-error {
    &input {
      border-color: red;
      background-color: rgba(red, 0.2);

      &::before {
        background-color: red;
      }
    }
  }

  &switch {
    &label {
      &::after {
        content: 'off';
        margin-left: 0.25rem;
      }
    }

    &input {
      width: (2 * $switchsize) - (2 * $checkboxbordersize);
      height: $switchsize;
      border: $checkboxbordersize solid $checkboxbordercolordisabled;
      border-radius: $checkboxcheckmarksize;

      &::before {
        top: $checkboxbordersize;
        right: $switchsize - $checkboxbordersize;
        bottom: $checkboxbordersize;
        left: $checkboxbordersize;
        border-radius: 50%;
        background: $checkboxcheckmarkcolordisabled;
      }

      &:checked {
        border-color: $checkboxbordercolor;

        + .cus-checkboxswitchlabel {
          &::after {
            content: 'on';
          }
        }

        &::before {
          right: $checkboxbordersize;
          left: $switchsize - $checkboxbordersize;
          background: $checkboxbordercolor;
        }
      }
    }
  }
}
