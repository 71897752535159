@use "@able/web/src" as able with ($include-breakpointLG: false);
@import '~styles/base.scss';

$btncolor: $primaryColour;
$btndisablecolor: #7e7e7e;

$bordercolor: $btncolor;
$borderdisablecolor: $btndisablecolor;
$contentcolor: $btndisablecolor;

.opt-out {
  margin-top: 36px;

  h3 {
    // @include heading3;
  }

  .blue-callout {
    position: relative;
    border-left: 4px solid #0064d2;
    background-color: #f7f7f7;
    padding: 24px 32px;
    height: auto;
    right: 24px;
    width: 100vw;
    font-weight: $font-weight-light;

    @include able.mediaQuery(breakpointMD) {
      width: calc(100% + 27px);
      right: 36px;
    }

    .callout-btn {
      position: relative;
      color: #0064d2;
      font-weight: $font-weight-normal;
    }
  }

  .icon-contain {
    svg {
      width: 124px;
      height: 124px;
    }
  }

  ul {
    position: relative;
    list-style-type: none;
    font-weight: $font-weight-light;

    li {
      position: relative;
      padding: 7px 0;
      padding-left: 32px;

      svg {
        position: absolute;
        left: 0px;
        top: 4px;
        transform: rotate(180deg);
      }
    }
  }

  .enrol-submit-btn {
    display: block;
    position: relative;
    padding: 15px 41px 15px 41px;
    font-size: 16px;
    border: 2px solid;
    color: $btncolor;
    font-weight: bold;
    text-align: center;
    text-decoration: none;

    cursor: pointer;

    &.disabled {
      color: #e0e0e0;
      border-color: #dddddd;
      cursor: default;
      &:hover {
        color: #e0e0e0;
        background: white;
      }
    }

    &:hover {
      color: white;
      background: $primaryColour;
    }
  }

  .keep-btn {
    position: relative;
    margin-top: 36px;
    text-decoration: underline;
  }

  .alert {
    color: red;
    &:hover {
      color: white;
      background: red;
    }
  }
}
