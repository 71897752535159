@use "@able/web/src" as able with ($include-breakpointLG: false);

.page-title-wrapper {
  @include able.mediaQuery(breakpointMD) {
    margin: 0;
    width: 100%;
  }
  background-color: white;
}

.page-title {
  display: block;
  background-color: #a788be;
  height: 400px;
}

.hidden-xs {
  display: block;
  @include able.mediaQuery(viewportXS) {
    display: none;
  }
}

.visible-xs {
  display: none;
  @include able.mediaQuery(viewportXS) {
    display: block;
  }
}
