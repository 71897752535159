@use "@able/web/src" as able with ($include-breakpointLG: false);

$separator-color: #dadada;
$accordion-hover-color: #f7f7f7;

.vertical-tab-parbase {
  position: relative;
  padding-left: 16px;
  margin-bottom: 24px;

  p {
    line-height: 21px;
  }
}

.vertical-tab-section {
  border-bottom: 1px solid $separator-color;

  &:first-child {
    border-top: 1px solid $separator-color;
  }
}

.parbase {
  // font-weight: $font-weight-light;
  padding: 12px 0;

  a {
    // font-weight: $font-weight-normal;
    padding: 24px 0;
    margin-left: 0;
  }

  ol {
    li {
      padding: 6px 0;
    }
  }
}

.rewards-tabs-container {
  // @include make-container();

  // color: $global-menu-font-color;

  .rewards-tabs-wrapper {
    // @include make-row();
    // margin-top: 36px;
    // margin-bottom: 72px;;
  }

  &.left {
    .rewards-tabs-wrapper {
      // @include make-col(7);
      // @include make-col-offset(1);
      // @include media-breakpoint-down(md) {
      //   @include make-col(12);
      // }
    }
  }
  &.right {
    .rewards-tabs-wrapper {
      // @include make-col(7);
      // @include media-breakpoint-down(md) {
      //   @include make-col(12);
      // }
    }
  }

  // @include media-breakpoint-down(md) {
  //   // @include make-col(12);
  // }

  .vertical-tab-heading {
    // @include make-col-ready();
    // @include make-col(7);
    font-size: 28px;
    font-weight: bold;
    line-height: 40px;
    margin: revert;
  }

  .vertical-tab-body {
    // @include make-col-ready();
    // @include make-col(7);
  }

  .vertical-tab-footer {
    // @include make-col-ready();
    // @include make-col(7);
    margin-top: 23px;
  }

  .vertical-tab-body + .primary-cta-18 {
    margin-top: 23px;
  }
}

.vertical-tab-body {
  list-style-type : none;
}

.rewards-vertical-tab-accordion {
  // color: $text-color-primary;
  // font-size: $font-size-h5;
  position: relative;
  .tab-title-container {
    margin: 0;
    button {
      @include able.TextStyle(Label);
      &:focus, &:active {
        outline: 1px solid able.colour(lozengeFeatured);
      }
      &:hover {
        color: able.colour(visited);
      }
    }
  }
  .vertical-tab-title {
    // color: $text-color-primary;
    display: flex;
    align-items: center;
    // font-size: $font-size-h5;
    // font-weight: $font-weight-bold;
    line-height: 24px;
    padding: 32px 30px 32px 8px;
    margin: 0;
    width: 100%;
    background: transparent;
    border: none;
    text-align: left;
    cursor: pointer;
  }

  .tcom-icon-12 {
    position: absolute;
    right: 8px;
    top: 34px;
    width: 14px;
    height: 14px;

    svg {
      position: absolute;
      top: 0;
      left: 0;
    }

    path {
      &[fill] {
        // fill: $primaryColour;
      }
    }
  }

  .accordion-open-icon {
    display: block;
    transform: rotateZ(90deg);
    transition-property: transform;
    transition-duration: 500ms;
    transition-timing-function: ease-in-out;
  }

  &[data-checked='checked'] {
    .vertical-tab-title {
      font-weight: normal;
    }
  }

  .vertical-tab-content {
    overflow: hidden;
    transition: height 500ms ease;
    padding-left: 8px;
    input.level2 {
      display: none;
    }
  }

  &.vertical-tab-active {
    .accordion-open-icon {
      transform: rotateZ(0deg);
    }
  }

  &.vertical-tab-hidden .vertical-tab-content {
    // font-weight: $font-weight-light;
    display: none;
  }
}