@use "@able/web/src" as able;

.tplus-rewards-colour-selector {
    width: calc(100% + 24px);
    margin-bottom: 16px;

    
    [class^="tplus-rewards-colour-selector-circle-button"] {
        margin-right:24px;
        margin-bottom: 24px;
        display:"inline-block";
        border-radius: 50%;
        border: 1px solid rgb(65, 65, 65);

        &:hover {
            box-shadow: 
                0 0 0 3px #fff,
                0 0 0 6px #5c5c5c,
                0 0 0 8px #5c5c5c29,
        }
    }

    .tplus-rewards-colour-selector-circle-button-selected {
        box-shadow: 
            0 0 0 3px #fff,
            0 0 0 6px #0064d2,
            0 0 0 8px #0066d229,
    }

    // .tplus-rewards-button-left {
    //     margin-left: 0;
    // }

    // .tplus-rewards-button-right {
    //     margin-right: 0
    // }
}