$font-family: 'Telstra Akkurat', Arial, sans-serif;

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-semibold: 500;
$font-weight-bold: 700;

$font-size-body-copy: 18px;

$font-size-h1: 44px;
$font-size-h2: 34px;
$font-size-h2-xs: 24px;
$font-size-h3: 28px;
$font-size-h4: 20px;
$font-size-h5: 16px;
$font-size-h6: 14px;

$secondary-cta-font-size: 16px;
$secondary-cta-small-font-size: 14px;

$header-signout-font-size: 16px;

$joinpage-fontsize-body-copy: 16px;

$breadcrumb-fontsize: 14px;

$font-body-copy-letter-spacing: 0px;

$font-h1-letter-spacing: -0.64px;
$font-h3-letter-spacing: -0.55px;
$sub-heading-letter-spacing: -0.75px;

$font-h1-line-height: 43px;
$font-subheading-line-height: 35px;

@mixin heading1 {
  font-size: $font-size-h1;
  font-weight: $font-weight-bold;
  letter-spacing: $font-h1-letter-spacing;
  line-height: $font-h1-line-height;
  margin: 0;
  margin-bottom: 24px;
}

@mixin heading2 {
  font-size: $font-size-h2;
  font-weight: $font-weight-bold;
  letter-spacing: $font-h1-letter-spacing;
  line-height: $font-h1-line-height;
  margin: 0;
}

@mixin heading3 {
  font-size: $font-size-h3;
  font-weight: $font-weight-bold;
  letter-spacing: $font-h3-letter-spacing;
  margin: 0;
}

@mixin sub-heading {
  font-size: $font-size-h3;
  font-weight: $font-weight-light;
  letter-spacing: $sub-heading-letter-spacing;
  line-height: $font-subheading-line-height;
  margin-bottom: 16px;
}