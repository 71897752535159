@import '~styles/base.scss';
.single-event-description {
  color: #414141;
  font-family: $font-family;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.13px;
  line-height: 22px;
  margin-bottom: 24px;
}
.disabled{
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}
.primary-cta-18{
  cursor: pointer;
  text-align: initial;
  &.true{
    color: currentColor;
    cursor: not-allowed;
    opacity: 0.5;
    text-decoration: none;
  }
}
.event-Summary{
  .event-tile {
    padding-bottom: 0;
    border: none;
  }
}