@use "@able/web/src" as able with ($include-breakpointLG: false);
@import '~styles/base.scss';

.tplus-benefits-tile {
  position: relative;
  img {
    display: block;
  }
  &__gradient {
    padding-top: 6px;
    &.gold {
      background: linear-gradient(
        90deg,
        #ba9359 0%,
        #e2caa4 38.28%,
        #c4a26c 100%
      );
    }
    &.silver {
      background: linear-gradient(
        90deg,
        #a7a7a7 0%,
        #e3e3e3 38.28%,
        #b5b5b5 100%
      );
    }
    &.member,
    &.business {
      background: #570066;
    }
  }
  &__content {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    /* optional */
    -webkit-box-align: start;
    -moz-box-align: start;
    -ms-flex-align: start;
    -webkit-align-items: flex-start;
    align-items: flex-start;
  }
  &__title {
    -webkit-box-ordinal-group: 1;
    -moz-box-ordinal-group: 1;
    -ms-flex-order: 1;
    -webkit-order: 1;
    order: 1;
  }
  &__heading {
    -webkit-box-ordinal-group: 2;
    -moz-box-ordinal-group: 2;
    -ms-flex-order: 2;
    -webkit-order: 2;
    order: 2;
  }
  &__subheading {
    -webkit-box-ordinal-group: 3;
    -moz-box-ordinal-group: 3;
    -ms-flex-order: 3;
    -webkit-order: 3;
    order: 3;
  }
  &__body {
    -webkit-box-ordinal-group: 4;
    -moz-box-ordinal-group: 4;
    -ms-flex-order: 4;
    -webkit-order: 4;
    order: 4;
  }
  &__fineprint {
    -webkit-box-ordinal-group: 5;
    -moz-box-ordinal-group: 5;
    -ms-flex-order: 5;
    -webkit-order: 5;
    order: 5;
  }
  &__status {
    -webkit-box-ordinal-group: 6;
    -moz-box-ordinal-group: 6;
    -ms-flex-order: 6;
    -webkit-order: 6;
    order: 6;
  }
  &__tier {
    top: able.spacing(spacing1x);
    left: able.spacing(spacing1x);
    position: absolute !important;
  }
}
