@use "@able/web/src" as able with ($include-breakpointLG: false);
@import '~styles/base.scss';

.close-modal-btn {
  position: absolute ;
  right: 4px;
  top: 4px;
  cursor: pointer;
  @include able.mediaQuery(breakpointMD) {
    right: 12px;
  }
}
