@use "@able/web/src" as able with ($include-breakpointLG: false);

.tplus-membership-dashboard {
  border-radius: 0.5rem;
  // overflow: hidden;
  &_tier_container {
    position: relative;
    border-bottom: 1px solid able.colour(dividerEmphasis);
    display: flex;
    @include able.mediaQuery(viewportXS) {
      display: initial;
    }
  }
  &_left_column {
    border-right: 1px solid able.colour(dividerEmphasis);
    @include able.mediaQuery(viewportXS_plus_SM) {
      border: none !important;
    }
  }
  &_expiry_container {
    position: relative;
    // margin-right: -16px;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    height: 100%;
    @include able.MessageInline(Attention);
    @include able.mediaQuery(breakpointMD) {
      margin-right: -20px;
    }

    &.warning {
      @include able.MessageInline(Warning);

      .button-tplus {
        text-decoration: none;
      }
    }

    &.attention {

      .button-tplus {
        text-decoration: none;
      }
    }

  }
  &_attention_icon {
    position: absolute;
    left: 2px;
  }
  &_warning_icon {
    position: absolute;
    left: 2px;
  }
  &_spendtodate {
    position: relative;
  }
  &_spend_icon {
    position: absolute;
    left: -40px;
  }
  &_main_div {
    background: #fff;
  }

  .tier-gradient {
    height: 0.5rem;
    width: 100%;
    position: absolute;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }

  &.GOLD {
    .tier-gradient {
      background: able.colour(telstraPlusTier2)
      // -webkit-linear-gradient(
      //   0deg,
      //   rgb(186, 148, 88) 0%,
      //   rgb(229, 205, 168) 31%,
      //   rgb(197, 163, 108) 100%
      // );
    }
  }
  &.SILVER {
    .tier-gradient {
      background: able.colour(telstraPlusTier3)
      // -webkit-linear-gradient(
      //   0deg,
      //   rgb(166, 166, 166) 0%, 
      //   rgb(227, 227, 227) 31%, 
      //   rgb(181, 181, 181) 100%
      // );
    }
  }
  &.MEMBER, &.BUSINESS {
    .tier-gradient {
      background: able.colour(telstraPlusTier1);
    }
  }
  .tier-col {
    li {
      list-style: none;
    }
    @include able.mediaQuery(breakpointSM) {
      li, li > * {
        display: inline;
      }
      .heading {
        border-right: 1px solid able.colour(dividerEmphasis);
      }
    }
    .heading {
      display: inline;
      padding-right: 1.5rem;
    }
  }
  .points-col {
    padding-top: 16px;
    padding-bottom: 24px;

    .points-inner-col {
        padding-top: 8px;
        padding-left: 24px;
        border-left: 1px solid rgba(0, 0, 0, 0.1);

        @include able.mediaQuery(viewportXS) {
          border-left: none;
          padding-left: 0px;
        }
    }

    @include able.mediaQuery(viewportXS) {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .button-col {
    margin-left: auto;
  }
}

