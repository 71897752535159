@use "@able/web/src" as able with ($include-breakpointLG: false);

.tplus-navigationBox > div:first-child:not(.tag-service-name) {
  height: 100%;
}

.tplus-navigationBox {
  @include able.mediaQuery(breakpointMD) {
    margin-bottom: 24px;
  }
}

.tplus-navigationBox.contentBase.col-lg-3.col-12 {
  flex: 0 0 25%;
  max-width: 25%;

  @include able.mediaQuery(viewportSM ) {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.tplus-navigationBox.contentBase.col-lg-4.col-12 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;

  @include able.mediaQuery(viewportSM ) {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.tplus-navigationBox.contentBase.col-lg-6.col-12 {
  flex: 0 0 50%;
  max-width: 50%;
}

.tplus-navigation-box {
  height: 100%;

  // @include media-breakpoint-only(xl) {
  //   margin-right: 24px;
  // }

  // @include media-breakpoint-only(lg) {
  //   margin-right: 16px;
  // }

  // @include able.mediaQuery(breakpointMD ) {
  //   margin-right: 8px;
  // }

  // @include able.mediaQuery(viewportSM ) {
  //   margin-right: 8px;
  // }
}

.tplus-navigation-box__able-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    display: block;
  }
  &:hover {
    background: rgba(0,0,0,.04);
  }
  transition: all 250ms;
  &.tplus-navigation-box--no-margin {
    margin-bottom: 0;
  }
}



.tplus-navigation-box__link {
  text-decoration: none;
}

.tplus-navigation-box__image-container {
  margin-top: 24px;
  margin-left: 16px;
  margin-right: 16px;
  margin-bottom: 16px;

  @include able.mediaQuery(breakpointMD) {
    margin-top: 32px;
    margin-left: 24px;
    margin-right: 24px;
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    margin-top: 0;
  }
}

.tplus-navigation-box__image {
  width: 100%;
  height: 100%;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    margin-top: 32px;
  }
}

.tplus-navigation-box__content {
  margin-bottom: 24px;
  margin-right: 16px;
  margin-left: 16px;

  @include able.mediaQuery(breakpointMD) {
    margin-bottom: 32px;
    margin-left: 24px;
    margin-right: 24px;
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    margin-bottom: 0;
  }
}

.tplus-navigation-box__heading {
  text-align: center;
}

.tplus-navigation-box__description {
  margin-top: 8px;
}

.tplus-navigation-box__description--screen-reader {
  @include able.mediaQuery(viewportXS) {
    display: none;
  }
}