@use "@able/web/src" as able;

.tplus-rewards-filter-toggle {
  &__heading {
    position: relative;
    @include able.mediaQuery(breakpointSM) {
      padding-top: 40px
    }
    padding: 13px 0 13px;
    border-bottom: 1px solid able.colour(dividerSubtle);
    button {
      cursor: pointer;
      position: absolute;
      right: 0;
      bottom: 3px;
    }
  }
}

.tplus-rewards-load-more {
  justify-content: center;
}

.tplus-rewards-heading-top {
  &::before {
    content: ' ';
    position: absolute;
    height: 42px;
    margin-left: -2.5%;
    margin-top: -0.1%;
    width: 8px;
    background-color: #a15f9e;
  }
}
