@use "@able/web/src" as able;


.tcom-section-navigation-container {
  color: #a0148c;
  width: 100%;
  margin: 0 auto;
}

.tcom-section-navigation__nav {
  // margin-top: -24px;
  
  @include able.mediaQuery(viewportSM) {
    margin-top: 16px;
  }

  @include able.mediaQuery(viewportXS) {
    margin-top: 24px;
  }
}

.tcom-section-navigation__content {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  column-gap: 24px;
  row-gap: 24px;
  margin: 0 auto;
  padding: 0;
  list-style-type: none;
  width: 100%;

  @include able.mediaQuery(viewportSM) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @include able.mediaQuery(viewportXS) {
    grid-template-columns: 1fr 1fr;
    column-gap: 16px;
    row-gap: 16px;
  }
}

.tcom-section-navigation__content--2 {
  grid-template-columns: 1fr 1fr;

  @include able.mediaQuery(breakpointSM) {
    width: 66.666666%;
  }

  @include able.mediaQuery(breakpointMD) {
    width: 50%;
  }
}

.tcom-section-navigation__content--3 {
  @include able.mediaQuery(breakpointMD) {
    width: 75%;
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.tcom-section-navigation__content--4 {
  @include able.mediaQuery(breakpointSM) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

.tcom-section-navigation__content--5 {
  @include able.mediaQuery(breakpointMD) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
}

.tcom-section-navigation__content__item {
  min-height: 140px;
  display: flex;

  @include able.mediaQuery(viewportSM) {
    margin: 0 4px;
  }
}

.tcom-section-navigation__content__able-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    // content: "";
    // cursor: pointer;
    // position: absolute;
    // top: 0;
    // bottom: 0;
    // left: 0;
    // right: 0;
    background: transparent;
    transition: 250ms,box-shadow 250ms,border-radius 250ms;
    box-shadow: 0 8px 10px 1px rgb(0 4 10 / 14%), 0 3px 14px 2px rgb(0 4 10 / 4%), 0 5px 5px -3px rgb(0 4 10 / 20%);

}

.tcom-section-navigation__content__link {
  text-decoration: none;
}

.tcom-section-navigation__content__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  width: 100%;
  padding-top: 40px;
  padding-bottom: 24px;

  @include able.mediaQuery(viewportXS) {
    padding-top: 32px;
  }
}

.tcom-section-navigation__content__image {
  width: 56px;
  height: 56px;
}

.tcom-section-navigation__content__text {
  color: #a0148c;
  padding: 8px 8px 0 8px;
  text-align: center;
  font-weight: 700;
  line-height: 1.4;
  margin: 0;
}
