@use "@able/web/src" as able with ($include-breakpointLG: false);
@import '~styles/base.scss';

.debug-panel {
  display: none;
  @include able.mediaQuery(breakpointMD) {
    display: block;
  }
  padding-top: 20px;
  height: 100%;
  overflow: scroll;
  position: fixed;
  bottom: 10px;
  left: 10px;
  background-color: rgba(0, 0, 0, 0.3);
  width: auto;
  border: 1px solid $primaryColour;
  border-radius: 2px;
  z-index: 999999;
  ul {
    list-style-type: none;
    li {
      margin-left: 6px;
    }
  }

  label {
    color: $primaryColour;
  }
  span {
    color: $primaryColour;
    input {
      color: $primaryColour;
    }
  }

  button,
  p {
    padding-left: 0;
    margin-left: 6px;
  }

  button {
    display: block;
    cursor: pointer;
  }

  input,
  select {
    width: 95%;
    margin: 0;
    padding: 8px;
  }

  &.show {
    height: 100%;
  }

  &.hideDebug {
    height: 75px;
  }
}
