@use "@able/web/src" as able with ($include-breakpointLG: false);
@import '~styles/base.scss';

.event-Summary {
  display: flex;
  flex-direction: column;
  font-family: $font-family;
  padding: 0.1px;
  //max-width: 456px;
  max-width: 100%;

  @include able.mediaQuery(viewportXS) {
    padding: 0;
  }
  &.disabled{
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
  }
  .team-name {
    width: 100%;
    float: left;
  }
  .event-tile {
    display: flex;
    flex-direction: column;
    padding: 24px 0px;
    border-bottom: 1px solid rgb(216, 210, 210);

    &.false {
      cursor: pointer;
    }

    .title {
      font-size: 16px;
      font-weight: bold;
      letter-spacing: -0.1px;
      line-height: 20px;
      // margin-bottom: 8px;

      .primary-cta-18 {
        float: right;
        margin: 0;
        padding: 0;

        &.true {
          cursor: not-allowed;
          opacity: 1;
          text-decoration: none;
          color: rgba(159, 20, 138, 0.4) !important;
        }
      }
    }

    .date {
      font-size: 16px;
      font-variation-settings: "wght" 300, "slnt" 0;
      letter-spacing: -0.13px;
      line-height: 22px;
      // margin-bottom: 8px;
    }

    .availability {
      font-size: 14px;
      font-variation-settings: "wght" 300, "slnt" 0;
      letter-spacing: -0.13px;
      line-height: 22px;

      &.true {
        color: #c91800;

        .alert-icon {
          vertical-align: bottom;
        }
      }
    }
  }
}
