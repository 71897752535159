@use "@able/web/src" as able with ($include-breakpointLG: false);
@import '~styles/base.scss';

.heading-title-container {
  display: flex;
  align-items: center;
  justify-content: left;
  padding-top: 8px;
  padding-right: 0px !important; // Temp fix for accessibility. This component will be decomissioned soon.
  padding-left: 0px !important;

  @media only screen and (max-width: 1359px) and (min-width: 1176px) {
    justify-content: center;
  }

  @media only screen and (max-width: 1023px) {
    flex-direction: column-reverse;
  }
  @include able.mediaQuery(viewportXS) {
    padding-top: 4px;
  }

  .title-base {
    background: #fff;
    border-left: 10px solid transparent;
    //top: -64px;
    position: relative;
    height: auto;
    width: 525px;
    margin-left: -42px;
    transform: translateX(82px);
    flex-flow: column;
    justify-content: center;
    -o-border-image: linear-gradient(
      0,
      #7076b2 0,
      #e090b6 49.75%,
      #a15f9e 100%
    );
    border-image: -webkit-gradient(
      linear,
      left bottom,
      left top,
      from(#7076b2),
      color-stop(49.75%, #e090b6),
      to(#a15f9e)
    );
    border-image: linear-gradient(0, #7076b2 0, #e090b6 49.75%, #a15f9e 100%);
    border-image-slice: 0 0 0 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    //padding: 40px 48px 40px 40px;
    padding: 24px 48px 56px 48px;


    @media only screen and (max-width: 1023px) {
      padding: 20px 16px 16px 16px;
      top: -64px;
      transform: none;
      margin-left: 0;
    }

    @media only screen and (max-width: 1023px) and (min-width: 767px) {
      width: 731px;
      align-self: auto;
      margin-right: 37px;
    }

    @media only screen and (max-width: 766px) {
      padding: 24px 16px 24px 16px;
      width: 95%;
      align-self: baseline;
      border-width: 10px;
      top: -34px;
    }
  }
  
  .subheading-section {
    font-size: 16px;
    font-weight: bold;
    letter-spacing: -0.1px;
    line-height: 20px;
  }

  .heading-section {
    @include able.TextStyle(HeadingB);
    padding-top: 16px;
  }

  .description-section {
    @include able.TextStyle(BaseBig, Paragraph);
    margin-top: 16px;
  }

  .signin-section {
    color: #a0148c;
    a,
    button {
      margin-top: 1rem;
      margin-right: 1rem;
      text-decoration: none;
      cursor: pointer;
    }
    li {
      list-style: none;
      display: inline;
    }
  }

  .term-section {
    @include able.TextStyle(FinePrint, Subtle);
    margin-top: 16px;
  }

  .arrow {
    color: #a0148c;
  }
  .eligible-txt {
    font-weight: normal;
    font-size: 14px;
  }

  figure {
    width: 100%;
    margin: 0;
  }

  .pageheader-image-container {
    
    display: block;
    height: auto;
    width: 100%;
    flex-shrink: 0;

    img {
      height: 100%;
      object-fit: cover;
    }

    figure {
      margin: 0;
      height: 100%;
    }


    @include able.mediaQuery(breakpointSM) {
      display: block;
      height: 434px;
      width: 768px;
      margin-left: 0;

      img {
        width: 768px;
      }
    }

    @include able.mediaQuery(breakpointMD) {
      height: 460px;
      width: 524px;
      margin-left: -56px;

      img {
        width: 524px;
      }
    }

  }
}

.offset {
  padding-top: 32px;
}