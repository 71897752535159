.tplus-delivery-information {
    display: flex;
    flex-direction: row;


    span {
        width: 100%;
    }

    p {
        margin: 0;
        width: auto;
    }

    a {
        color: #0064D2;
    }

    .tplus-delivery-information-text {
        margin-top: 3px;
        margin-left: 8px;
    }
}

