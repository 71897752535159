@import '~styles/base.scss';
.sports-dropdown-wrapper {
    min-width: 250px;
    max-width: 380px;
    position: relative;
  
    .dropdown-btn {
      width: 100%;
      min-height: 34px;
      padding: 7px 0;
      outline: none;
      border: none;
      box-sizing: border-box;
      border-bottom: 1px solid #ccc;
  
      text-align: left;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 22.4px;
      color: #282828;
      position: relative;
      font-family: $font-family;
  
      .chevron {
        position: absolute;
        top: 0;
        right: 0;
      }
    }
  
    .dropdown-menu {
      position: absolute;
      max-height: 300px;
      overflow-x: hidden;
      overflow-y: auto;
      width: 100%;
      margin-top: 10px;
      outline: #ccc !important;
      box-shadow: 0 0 8px #ccc;
      background-color: white;
      z-index: 2;
  
      .input-field {
        position: relative;
        .dropdown-search {
          margin: 24px 20px 0;
          width: calc(100% - 40px);
          height: 41px;
          outline: none;
          padding-bottom: 5px;
          font-size: 16px;
    
          &::-webkit-input-placeholder {
            color: #ccc;
          }
        
          &:focus::-webkit-input-placeholder {
            color: transparent;
          }
        }
        .search-icon {
          position: absolute;
          bottom: 0;
          right: 20px;
        }
      }
  
      .dropdown-option {
        height: 50px;
        display: flex;
        align-items: center;
        border-left: 2px solid white;
        padding-left: 20px;
        font-size: 18px;
        letter-spacing: -0.5px;
        line-height: 19px;
        font-family: $font-family ;
        font-weight: 300;

        cursor: pointer;
        &:hover {
          border-left: 2px solid #a0148c;
        }
  
        &.selected {
          color: #a0148c;
          font-weight: 300;
        }
  
        &:active {
          background-color: #FFEFF7;
        }
  
        &:first-child {
          margin-top: 16px;
        }
        &:last-child {
          margin-bottom: 15px;
        }
      }
  
      .no-result {
        display: block;
        position: relative;
        padding: 20px 24px 20px 55px;
        color: #414141;
        font-family: $font-family;
        font-size: 14px;
        letter-spacing: 0.18px;
        line-height: 21px;
        .alert-icon {
          position: absolute;
          left: 24px;
          top: 17px;
        }
      }
  
      &::-webkit-scrollbar
      {
        width: 12px;
      }
      &::-webkit-scrollbar-thumb
      {
        border: 4px solid white;
        border-radius: 15px;
        // -webkit-box-shadow: inset 0 0 6px #e7e7e7;
        background-color: #e7e7e7;
      }
    }
  
    .form-error {
      position: absolute;
      padding-left: 0;
      display: flex;
      align-items: center;
      margin-top: 10px;
      color: #414141;
      font-size: 14px;
      .icon-alert {
        width: 24px;
        height: 24px;
      }
    }
  
    // Mobile view
    @media (max-width: 768px) {
      max-width: 100%;
      .dropdown-menu {
        width: 100vw;
        left: -31px;
        .dropdown-option {
          padding-left: 30px;
        }
        .input-field {
          .dropdown-search {
            margin-left: 30px;
            width: calc(100% - 48px);
          }
          .search-icon {
            right: 17px;
          }
        }
      }
    }
  }
  
  