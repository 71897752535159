@use "@able/web/src" as able with ($include-breakpointLG: false);

.table-container {
  .table-row {
    .mobile-member-tier-table {
      &.NONE {
        .tabs {
          li {
            min-width: 20%;
          }
        }
      }
      @include able.mediaQuery(breakpointMD) {
        display: none;
      }

      .tabs {
        list-style-type: none;
        padding-bottom: 0px;

        li {
          @include able.TextStyle(Subheading);
          display: inline-block;
          text-align: center;
          border-bottom: 1px solid able.colour(borderSubtle);
          min-width: 33.33%;
          button {
            padding: able.spacing(spacing2x);
            color: able.colour(textBase);
            outline: 0;
          }

          &.activeMember,
          &.activeBusiness {
            // padding-bottom: 6px;
            border-bottom: 3px solid able.colour(telstraPlusTier1);
            button {
              color: able.colour(telstraPlusTier1);
            }
          }

          &.activeSilver {
            border-bottom: 3px solid able.colour(telstraPlusTier2);
            button {
              color: able.colour(telstraPlusTier2);
            }
          }
          &.activeGold {
            border-bottom: 3px solid able.colour(telstraPlusTier3);
            button {
              color: able.colour(telstraPlusTier3);
            }
          }
        }
      }
      .benefit-tile {
        padding: able.spacing(spacing3x) 0;
        @include able.TextStyle(Base);
        background-color: able.colour(white);
        border-radius: 0;
        overflow: hidden;

        .member-callout {
          position: relative;
          left: 0;
          width: auto;
          height: auto;
          // padding: 6px 2px 6px 8px;
          // padding-left: 2px;
          // font-size: 14px;
          background: #f2f2f2;
          // color: $text-color-secondary-accessible;
        }

        .text-container {
          padding-bottom: able.spacing(spacing3x);
          .tile-title {
            @include able.TextStyle(HeadingB);
          }

          .tile-avg-spend {
            @include able.TextStyle(Tag);
            padding-bottom: able.spacing(spacing1x);
          }

          .tile-copy {
            @include able.TextStyle(Base);
          }
        }

        .benefits-list {
          list-style-type: none;

          .tick-icon-column {
            text-align: right;
          }

          li {
            padding-bottom: able.spacing(spacing1x);
            .title-span {
              @include able.TextStyle(HeadingD);
              padding: able.spacing(spacing2x);
              display: block;
              background-color: able.colour(textInvertedBase);
            }
          }
          &.member {
            .tick-icon {
              svg {
                path {
                  stroke: able.colour(telstraPlusTier1);
                  fill: able.colour(telstraPlusTier1);
                }
              }
            }
          }
          &.silver {
            .tick-icon {
              svg {
                path {
                  stroke: able.colour(telstraPlusTier2);
                  fill: able.colour(telstraPlusTier2);
                }
              }
            }
          }
          &.gold {
            .tick-icon {
              svg {
                path {
                  stroke: able.colour(telstraPlusTier3);
                  fill: able.colour(telstraPlusTier3);
                }
              }
            }
          }
          &.business {
            .tick-icon {
              svg {
                path {
                  stroke: able.colour(telstraPlusTier1);
                  fill: able.colour(telstraPlusTier1);
                }
              }
            }
          }
        }

        &.member {
          .tile-title {
            color: able.colour(telstraPlusTier1);
          }
        }

        &.silver {
          .tile-title {
            color: able.colour(telstraPlusTier2);
          }
          &.active {
            padding-bottom: 6px;
            border-bottom: 3px solid able.colour(telstraPlusTier2);
            button {
              color: able.colour(telstraPlusTier2);
            }
          }
        }

        &.gold {
          .tile-title {
            color: able.colour(telstraPlusTier3);
          }
        }

        &.business {
          .tile-title {
            color: able.colour(telstraPlusTier1);
          }
        }
      }
    }

    .member-tier-table {
      // display: none;
      position: relative;
      border-collapse: collapse;
      color: able.colour(textBase);
      width: 100%;
      &.BUSINESS {
        th,
        td {
          width: 50%;
        }
      }
      th {
        @include able.TextStyle(Base);
        color: able.colour(textBase);
        text-align: left;
        width: 20%;
        border: 1px solid;
        border-color: able.colour(borderSubtle);
        padding: able.spacing(spacing3x);
        vertical-align: top;

        .member-callout {
          position: absolute;
          // width: 200px;
          left: 0;
          top: 0;
          background: able.colour(textInvertedBase);
          height: auto;
          width: 100%;
          color: #757575;
        }
        .tile-avg-spend {
          margin-bottom: 0;
        }
        > .tile-copy {
          margin-top: 0;
        }
        .tile-copy {
          color: able.colour(textInvertedSubtle);
        }
        .tile-title {
          @include able.TextStyle(HeadingD);
          margin-bottom: 0;
        }

        &.member {
          border-top: 3px solid able.colour(telstraPlusTier1);
          .tile-title {
            color: able.colour(telstraPlusTier1);
          }
        }
        &.silver {
          border-top: 3px solid able.colour(telstraPlusTier2);
          .tile-title {
            color: able.colour(telstraPlusTier2);
          }
        }
        &.gold {
          border-top: 3px solid able.colour(telstraPlusTier3);
          .tile-title {
            color: able.colour(telstraPlusTier3);
          }
        }
        &.business {
          border-top: 3px solid able.colour(telstraPlusTier1);
          .tile-title {
            color: able.colour(telstraPlusTier1);
          }
        }
        &:first-child {
          border: 0;
        }
      }

      td,
      th {
        padding: able.spacing(spacing3x);
        color: able.colour(textTertiary);
        vertical-align: top;
      }

      .title-row {
        border: 1px solid able.colour(borderSubtle);
        background-color: able.colour(textInvertedBase);

        th,
        td {
          border: 1px solid able.colour(borderSubtle);
          color: able.colour(textTertiary);
          .title-span {
            @include able.TextStyle(HeadingD);
          }
        }
      }

      .content-row {
        th,
        td {
          border: 1px solid;
          border-color: able.colour(borderSubtle);
          width: 20%;
          color: able.colour(neutralForegroundNormal);
          @include able.TextStyle(Base);

          &.has-text {
            text-align: center;
            strong {
              color: able.colour(textBase);
            }
          }
        }
        td {
          text-align: center;
        }
      }
    }

    .member-tick {
      stroke: able.colour(telstraPlusTier1);
    }
    .silver-tick {
      stroke: able.colour(telstraPlusTier2);
    }
    .gold-tick {
      stroke: able.colour(telstraPlusTier3);
    }
  }
}
