@use "@able/web/src" as able with ($include-breakpointLG: false);
@import '~styles/base.scss';
@import '~utils/forms/formsPages.scss';

$btncolor: $primaryColour;
$btndisablecolor: #7e7e7e;

$bordercolor: $btncolor;
$borderdisablecolor: $btndisablecolor;
$contentcolor: $btndisablecolor;

$spacing: 20px;
$elem-margin: 20px 0 10px 0;
$button-padding: 10px;
$check-right-spacing: 10px;

.enrol-container {
  *:focus {
    outline: none;
  }
  color: #414141;
  margin-top: 48px;
  padding: 24px 0 80px 0;

  .edit-details {
    position: relative;
    top: 12px;
    padding: 24px 0 0 0;

    .customer-info {
      position: relative;
      padding: 12px 0;
      border-bottom: 1px solid $light-border-color;
      p {
        margin: 6px 0;
        font-size: 16px;
        font-weight: $font-weight-bold;
        letter-spacing: -0.1px;
        line-height: 20px;

        &.missing-details {
          font-size: 20px;
          font-weight: 300;
        }
      }

      span {
        p {
          font-weight: $font-weight-light;
          font-size: 22px;
          letter-spacing: -0.42px;
          line-height: 31px;
          word-wrap: break-word;
          overflow: hidden;
          padding-right: 8px;
        }
      }

      .successful-update {
        display: flex;
        font-size: 16px;
        width: 80%;
        position: relative;
        top: 6px;
        img {
          position: relative;
          top: -2px;
          margin-right: 6px;
        }
      }

      button {
        &:hover {
          cursor: pointer;
        }
      }
      .edit-btn {
        position: absolute;
        right: 0;
        top: 20px;
        font-size: 14px;
        text-decoration: underline;
      }
    }
  }

  .btn-container {
    display: block;
    padding: 24px 0;
  }

  .learn-more {
    display: block;
    position: relative;
    padding: 12px 0;
    text-align: left;
    font-size: 16px;
    text-decoration: underline;
    &:hover {
      cursor: pointer;
    }

    // @include media-breakpoint-down(md) {
    //   font-size: 14px;
    //   text-decoration: underline;
    //   width: 80%;
    //   padding: 24px 0;
    //   margin: 0px 0 12px 0;
    // }
  }
}

.tplus_EnrolmentView {
  .otp-flow {
    // @include make-col(5);
  }
  .enrol-not-now-btn,
  .enrol-learn-more-btn {
    padding-left: 0;
    margin-bottom: 16px;
    margin-left: 0;
    font-size: $secondary-cta-small-font-size;
  }

  .enrol-not-now-btn {
    position: relative;
    top: 12px;
    margin: 36px 0;
  }

  .enrol-learn-more-btn {
    text-decoration: underline;
    font-size: $secondary-cta-font-size;
    margin: 12px 0 24px 0;
  }

  .enrol-submit-error {
    color: #d0021b;

    img {
      position: relative;
      top: 6px;
      margin-right: 5px;
    }
  }

  .enrol-submit-btn {
    position: relative;
    padding: 15px 41px 15px 41px;
    // background: $global-white;
    font-size: 16px;
    border: 2px solid;
    color: $btncolor;
    font-weight: bold;
    width: 100%;
    max-width: 360px;
    margin: 24px 0;

    cursor: pointer;

    &.disabled {
      color: #e0e0e0;
      border-color: #dddddd;
      cursor: default;
      &:hover {
        color: #e0e0e0;
        background: white;
      }
    }

    &:hover {
      color: white;
      background: $primaryColour;
    }
  }

  .enrol-high-demand {
    font-size: 14px;
  }

  .enrol-small-print {
    color: #757575;
    font-size: 14px;
    letter-spacing: -0.2px;
    line-height: 20px;
    margin-bottom: 24px;
  }

  .tnc-content-wrap {
    padding: 20px;
    padding-bottom: 40px;
  }

  .enrol-tnc-field-wrap {
    position: relative;
    padding: 0 32px 32px 32px;
    padding-left: 30px;

    .tnc-content {
      font-size: 14px;
      color: $text-color-secondary-accessible;
      margin-left: 12px;
      display: block;
      line-height: 20px;
    }
    input {
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  &.fields {
    .enrol-tnc-field-wrap {
      margin-bottom: 32px;
    }
  }

  .rewards-tnc-button {
    display: inline;
    background: none;
    border: none;
    text-decoration: underline;
    cursor: pointer;
    padding: 0;
    font-size: 16px;

    &:hover {
      text-decoration: none;
    }
  }

  ._alert-div {
    // @extend .alert-div;
    margin-top: 0;
    margin-bottom: 32px;
  }

  &.fields {
    ._alert-div {
      margin-top: 40px;
      margin-bottom: 0;
    }
  }
}
