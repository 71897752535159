@use "@able/web/src" as able with ($include-breakpointLG: false);

//top most instruction tile level
.tplus-instructional-tiles {
  .instructional-tiles-container {
    display: flex;
    // color: $textPrimary;

    .stepper-container {
      margin-right: 16px;
      display: flex;
      flex-direction: column;

      svg {
        flex-shrink: 0;
      }

      .stepper-t-line {
        height: 100%;
        display: flex;
        justify-content: center;
        padding-top: 8px;
        padding-bottom: 8px;

        .t-line {
          height: 100%;
          width: 2px;
          background: linear-gradient(#5bc5f2 0%, #c69dc9 49.69%, #8779b7 100%);
        }
      }

      @include able.mediaQuery(breakpointMD) {
      // @include media-breakpoint-up(lg) {
        flex-direction: row;
        align-items: center;
        width: 100%;

        .stepper-t-line {
          width: 100%;
          padding: 0;
          height: 2px;

          .t-line {
            width: 100%;
          }
        }
      }
    }

    .tile-content {
      display: flex;
      flex-direction: column;
      height: 100%;

      .tile-headline {
        flex-shrink: 0;
        margin-top: 0;
        padding-bottom: 16px;

        // @extend %tcom-able__text-heading-d;
      }

      .tile-body {
        margin-top: 0;
        padding-bottom: 8px;
        color: able.colour(textBase);

        // @extend %tcom-able__text-body-short;
      }

      .cta-section {
        margin-top: 0;
        margin-bottom: 24px;
        display: flex;
        align-items: flex-end;
        height: 100%;

        .tile-content-link {
          // @extend %tcom-able__button-low-emphasis;
        }
      }
    }
  }

  &__list {
    display: flex;
    list-style: none;
    padding: 0;
    margin-top: 0;
    margin-bottom: 0;
    flex-wrap: wrap;
    @include able.mediaQuery(breakpointMD) {
      flex-wrap: nowrap;
    }
  }

  //remove last overview tile step t-line
  [class*="col-"]:last-child .instructional-tiles-container {
    .stepper-container .stepper-t-line .t-line {
      display: none;
    }

    .tile-content .cta-section {
      @include able.mediaQuery(viewportXS) {
      // @include media-breakpoint-down(sm) {
        padding-bottom: 0;
      }
    }
  }

  //show t-line when only 1 tile is configured
  [class*="col-"]:only-child .instructional-tiles-container .stepper-container .stepper-t-line .t-line {
    display: block;
  }

  //t-line styles when 3 tiles are configured
  [class*="col-"]:first-child:nth-last-child(3) .instructional-tiles-container .stepper-container .stepper-t-line .t-line {
    background: linear-gradient(#5bc5f2 0%, #c69dc9 100%);
  }

  [class*="col-"]:first-child:nth-last-child(3) ~ [class*="col-"]:nth-child(2) .instructional-tiles-container .stepper-container .stepper-t-line .t-line {
    background: linear-gradient(#c69dc9 0%, #8779b7 100%);
  }

  //t-line styles when 4 tiles are configured
  [class*="col-"]:first-child:nth-last-child(4) .instructional-tiles-container .stepper-container .stepper-t-line .t-line {
    background: linear-gradient(#5bc5f2 0%, #9dadd9 49.69%, #a3aad6 100%);
  }

  [class*="col-"]:first-child:nth-last-child(4) ~ [class*="col-"]:nth-child(2) .instructional-tiles-container .stepper-container .stepper-t-line .t-line {
    background: linear-gradient(#a3aad6 0%, #c69dc9 49.69%, #b191c3 100%);
  }

  [class*="col-"]:first-child:nth-last-child(4) ~ [class*="col-"]:nth-child(3) .instructional-tiles-container .stepper-container .stepper-t-line .t-line {
    background: linear-gradient(#b191c3 0%, #9b84bd 49.69%, #8779b7 100%);
  }
}

//default tile width - mobile first
.col-instructional-tile {
  // flex: 0 0 100%;
  // max-width: 100%;
  width: 100%;
}
@include able.mediaQuery(breakpointMD) {
// @include media-breakpoint-up(lg) {
  .instructional-tiles-container {
    flex-direction: column;
    height: 100%;

    .tile-content {
      padding-right: 40px;
    }

    .stepper-container {
      margin-bottom: 16px;
      flex-direction: row;
      flex-shrink: 0;

      .stepper-icon {
        height: 32px;
        width: 32px;
      }
    }
  }

  // .col-instructional-tile:first-child:nth-last-child(4) ~ .col-instructional-tile,
  // .col-instructional-tile:first-child:nth-last-child(4) {
  //   //set width of each tile column based on number of tiles authored
  //   flex: 0 0 25%;
  //   max-width: 25%;
  // }

  .instructional-tiles .instructional-tiles-container .tile-content {
    margin-left: 16px;
  }

  // //set width of each tile column based on number of tiles authored
  // .col-instructional-tile:first-child:nth-last-child(4) ~ .col-instructional-tile,
  // .col-instructional-tile:first-child:nth-last-child(4),
  // .col-instructional-tile:first-child:nth-last-child(2) ~ .col-instructional-tile,
  // .col-instructional-tile:first-child:nth-last-child(2) {
  //   flex: 0 0 50%;
  //   max-width: 50%;
  // }

  // .col-instructional-tile:first-child:nth-last-child(3) ~ .col-instructional-tile,
  // .col-instructional-tile:first-child:nth-last-child(3) {
  //   flex: 0 0 33.33333%;
  //   max-width: 33.33333%;
  // }
}