@use "@able/web/src" as able with ($include-breakpointLG: false);

$overlapping-area-sm: 32px;
$overlapping-area-md-up: 64px;
$overlapping-area-xl: 72px;

.width-correct-wrapper {
  flex: 0 0 100%;
  position: relative;
  margin: 0 auto;
  width: calc(85% + 33px);

  // @include media-breakpoint-up(xl) {
  //   color: black;
  // }
  // @media (min-width: 992px) {}

  // @include media-breakpoint-only(lg) {
  //   color: blue;
  // }
  // @media (min-width: 992px) and (max-width: 1199.98px) {}

  // @include media-breakpoint-down(sm) {
  //   color: green;
  // }
  // @media (max-width: 779.98px) {}

  // @include media-breakpoint-up(sm) {
  //   color: orange;
  // }
  // @media (min-width: 576px) {}

  // @include media-breakpoint-down(xs) {
  //   color: pink;
  // }
  // @media (max-width: 575.98px) {}

  // @include media-breakpoint-down(md) {
  //   color: magenta;
  // }
  // @media (max-width: 991.98px) {}

  @media (min-width: 768px) {
    padding-left: 12px;
    padding-right: 12px;
    width: 680px;
    max-width: 680px;
  }
  @media (min-width: 1025px) {
    width: 952px;
    max-width: 952px;
    // margin-left: 70px; 
  }
  @media (min-width: 1360px) {
    width: 1160px;
    max-width: 1160px;
    margin-left: -34px; 
  }
}

.tplus-pageheader-heading--top {
  &::before {
    content: ' ';
    position: absolute;
    height: 49px;
    left: -2.9%;
    width: 10px;
    top: -1px;
    background-color: #a15f9e;
  }

  // margin: 0 mobile-image-offset() * -1;
  width: 660px;

  padding: 8px 8px 8px 2px;
  margin-bottom: 8px;
  display: inline-block;

  @media (max-width: 1359.98px) {
    &::before {
      left: -3.8%;
    }
  }

  @media (max-width: 1024px) {
    padding-left: 0.5%;
    margin-left: -8px;

    &::before {
      left: -6.4%;
      height: 44px;
    }
  }

  @media (max-width: 768px) {
    width: 90vw;
    transform: translateX(-7.5%);
    padding-left: 8.5%;
    padding-left: 32px;

    &::before {
      left: -1.5%;
    }
  }
}

.tplus-page-header-brand {
  width: 100%;
  display: flex;
  align-items: center;
}

.tplus-pageheader-brand-message {
  display: none;

  @media (min-width: 992px) {
    display: inline-block;
    text-align: right;
    flex-grow: 1;
    white-space: nowrap;
    padding-bottom: 8px;
    margin-bottom: 8px;
  }
}

.tplus-pageheader-brand-message-link {
  position: relative;
  padding: 2px;
  align-self: center;

  @media (min-width: 992px) and (max-width: 1199.98px) {
    padding: 0;
  }
}

.tplus-pageheader-brand-message-text {
  @include able.TextStyle(Base);
  @include able.InlineLinks();

  color: #414141;
  width: 100%;
  padding: 2px;

  @media (min-width: 992px) and (max-width: 1199.98px) {
    padding: 4px 0 0 0;
  }
}

.tplus-pageheader-brand-message-icon {
  fill: #414141;
  vertical-align: middle;
}

.tplus-pageheader {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media (max-width: 1024px) {
    flex-direction: column-reverse;
  }

  @media (max-width: 1024px) {
    width: 117.6470588235%;
    margin: 0 -8.823529412%;
  }

  .tplus-pageheader-cta {
    &.primary {
      margin-top: 16px;

      &:nth-of-type(odd) {
        margin-right: 24px;

        @media (max-width: 779.98px) {
          margin-right: 16px;
        }
      }

      a {
        @include able.ActionButton(MediumEmphasis);
      }
    }

    &.secondary {
      a {
        @include able.ActionButton(LowEmphasis);

        margin-top: 8px;
      }
    }
  }

  .tplus-pageheader-image {
    display: block;
    height: 400px;
    width: 700px;
    flex-shrink: 0;

    img {
      width: 764px;
      height: 100%;
      object-fit: cover;
      max-width: 764px;
    }

    figure {
      margin: 0;
      height: 100%;
    }

    &.tier2 {
      height: 500px;
    }

    @media (max-width: 1359.98px) {
      height: 360px;
      width: 524px;
      margin-left: -56px;

      img {
        width: 524px;
      }

      &.tier2 {
        height: 460px;
        margin-left: 0;
        margin-right: -40px;
      }
    }

    @media (min-width: 992px) and (max-width: 1199.98px) {
      &.tier3 {
        height: 360px;
        margin-left: 0;
        margin-right: -40px;
      }
    }

    @media (max-width: 1024px) {
      display: block;
      height: 434px;
      width: 768px;
      margin-left: 0;

      img {
        width: 768px;
      }

      &.tier2 {
        height: 434px;
        margin-right: 0;
      }
    }

    @media (max-width: 779.98px) {
      display: block;
      height: auto;
      width: 100%;

      img {
        width: 100%;
      }

      &.tier2 {
        height: auto;
      }
    }
  }

  &.no-image {
    width: 100%;
    display: block;

    @media (max-width: 991.98px) {
      margin-left: 0;
    }

    @media (min-width: 1200px) {
      margin-left: -80px;
    }
  }

  &.tplus-pageheader--with-image {
    position: relative;
    color: transparent;

    &::before {
      border-image: linear-gradient(0deg,#7076b2,#e090b6 49.75%,#a15f9e);
      border-image-source: linear-gradient(0deg, rgb(112, 118, 178), rgb(224, 144, 182) 49.75%, rgb(161, 95, 158));
      border-image-slice: 0 0 0 1;
      border-image-width: 1;
      border-image-outset: 0;
      border-image-repeat: stretch;
    }

    @media (min-width: 1025px) and (max-width: 1359.98px) {
      &::before {
        content: ' ';
        position: absolute;
        height: 100%;
        left: -5.2%;
        border-style: solid;
        border-image-slice: 0 0 0 1;
        top: 0;
        border-width: 0 10px;
      }
    }
  }

  .tplus-pageheader-content-container {
    &::before {
      content: ' ';
      position: absolute;
      height: 100%;
      left: -32px;
      border-style: solid;
      top: 0;
      border-width: 0 10px;

      border-image: linear-gradient(0deg,#7076b2,#e090b6 49.75%,#a15f9e);
      border-image-source: linear-gradient(0deg, rgb(112, 118, 178), rgb(224, 144, 182) 49.75%, rgb(161, 95, 158));
      border-image-slice: 0 0 0 1;
      border-image-width: 1;
      border-image-outset: 0;
      border-image-repeat: stretch;
    }

    @media (min-width: 1025px) and (max-width: 1359.98px) {
      &::before {
        border: none;
      }
    }

    position: relative;
    background: #fff;
    width: 592px;
    height: 100%;
    padding: 32px 32px 32px 16px;
    max-height: 320px;
    margin-bottom: 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    flex-shrink: 0;
    transform: translateX($overlapping-area-md-up);
    z-index: 1;

    @media (min-width: 992px) {
      padding-left: 8px;
    }

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      display: block;
    }

    @media (min-width: 1200px) {
      min-height: 260px;
    }

    &.tier2 {
      max-height: none;
    }

    @media (max-width: 1359.98px) {
      padding: 24px 24px 24px 8px;
      width: 456px;
      max-height: 296px;
      transform: translateX(18px);

      &.tier2 {
        justify-content: center;
        transform: translateX(0);
      }

      &::before {
        left: -42px;
      }
    }

    @media (max-width: 1025px) {
      transform: none;
      width: 100%;
      padding: 24px 24px 16px 10%;

      &::before {
        left: 2px;
        width: 10%;
        background-color: white;
        z-index: -1;
      }

      &.tier2 {
        height: auto;
      }
    }

    @media (max-width: 779.98px) {
      padding: 24px 24px 16px 15%;
      margin-right: 64px;

      &::before {
        left: 32px;
        top: 0;
        height: 100%;
      }
    }

    @media (max-width: 575.98px) {
      padding-left: 18%;

      &::before {
        top: 0;
        left: 30px;
      }
    }

    &.no-image {
      width: 100%;
      min-height: 0;
      top: 0;
      padding: 0 32px 0 16px;
      max-width: 840px;
      max-height: none;

      .tplus-pageheader-heading {
        margin: 8px 0 8px 0;
      }

      .tplus-pageheader-headline {
        margin: 8px 0 8px 0;
      }

      .tplus-pageheader-bodycopy {
        margin-top: 8px;
      }

      .tplus-pageheader-tnc {
        margin-top: 16px;
      }

      &::before {
        width: 10px;
        border: none;
        height: 49px;
      }

      &.tplus-pageheader--title-highlighter {
        &::before {
          height: 68px;
        }
      }

      @media (max-width: 992px) {
        transform: none;
        padding-left: 0;

        &::before {
          left: -48px;
        }
      }

      @media (max-width: 991.98px) {
        &::before {
          left: -56px;
          height: 44px;
        }

        &.tplus-pageheader--title-highlighter {
          &::before {
            height: 58px;
          }
        }
      }

      @media (max-width: 779.98px) {
        &::before {
          left: -9%;
        }
      }
    }

    .tplus-pageheader-tnc,
    .tplus-pageheader-bodycopy {
      margin-top: 16px;
    }

    .tplus-pageheader-headline {
      display: block;
    }

    .tplus-pageheader-cta-container {
      display: block;
    }

    .tplus-pageheader-cta.primary,
    .tplus-pageheader-cta.secondary {
      display: block;

      @media (min-width: 779.98px) {
        display: inline-block;
      }
    }
  }
}
