@use "@able/web/src" as able with ($include-breakpointLG: false);
@import '~styles/base.scss';

.tplus-breadcrumbs {

  .tplus-breadcrumbs-list {
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    list-style: none;

    .tplus-breadcrumbs-item {
      font-size: 14px;
      display: flex;
      align-items: baseline;

      a {
        color: $text-color-secondary;
        line-height: 1.5;
        padding: 0.875rem 8px;
      }

      &:first-child {
        a {
          padding-left: 8px;

          @include able.mediaQuery(breakpointMD) {
            padding-left: 8px;
          }
        }
      }

      a:hover {
        color: able.colour(interactiveForegroundNormal);
      }

      .breadcrumb-decorative-text-after {
        color: $text-color-secondary;
      }

      .breadcrumb-decorative-text-before {
        display: none;
      }

      @include able.mediaQuery(viewportXS_plus_SM) {
        display: none;

        &:nth-last-child(1) {
          display: flex;

          &::after {
            display: none;
          }
        }

        .breadcrumb-decorative-text-before {
          display: block;
          color: $text-color-secondary;
        }

        .breadcrumb-decorative-text-after {
          display: none;
        }
      }
    }
  }
}
