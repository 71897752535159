@use "@able/web/src" as able with ($include-breakpointLG: false);
@import '~styles/base.scss';


.event-child-page{
    margin-top: 0;
    max-width: initial;
    padding: 0px 20px;
}

.lineHeight{
    line-height: 10px;
    margin-top: 8px;
}
.lineHeight:first-child{
    .gold-bold-font:first-child{
        font-weight:bold;
    }
}
.lineHeight:last-child{
    .gold-bold-font:first-child{
        font-weight:bold;
    }
}
.alert-text{
    .gold-bold-font:first-child{
        font-weight: bold;
    }
}
.event-page{
    display: flex;
    @include able.mediaQuery(breakpointMD) {
        padding-top: 16px;
    }
    @include able.mediaQuery(viewportXS_plus_SM) {
        display:inline-block;
    }
    .teamContainer{
        .team-dropdown{
            @include able.mediaQuery(breakpointSM) {
                float:left;
                // @include make-col(6);
            }
            .dropdown-btn{
                width:95%;
                .sports-downshift{
                    width: 90%;
                    float: left;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis
                }  
            }
            
        }
        .venue-dropdown{
            @include able.mediaQuery(breakpointSM) {
                float:right;
                // @include make-col(6);
            }
            .dropdown-btn{
                width:95%;
                @include able.mediaQuery(breakpointMD) {
                    float:right;
                }
                .sports-downshift{
                    width: 90%;
                    float: left;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis
                }  
            }
        }
    }
}

// styles for all event tabs
.tabs-container {
    position: relative;
    margin:16px 0px;
    .tab-header-wrapper {
        width:100%;
        display: flex;
        .tab-header {
            @include able.TextStyle(FinePrint);
            flex-grow: 1;
            text-align: center;
            // font-size: 16px;
            list-style-type: none;
            padding:5px 2px 20px;
            // color: #414141;
            border-bottom: 2px solid #d8d8d8;
            cursor:pointer;
            &.active {
                color:able.colour(interactiveForegroundNormal);
                border-bottom: 2px solid able.colour(interactiveForegroundNormal);
            }
        }
        @include able.mediaQuery(viewportXS) {
            position: absolute;
            left: -32px;
            width: 100vw;
            overflow-x: auto;
            height: 37px;
            .tab-header {
                min-width: 100px;
                white-space: nowrap;
                padding-bottom: 12px;
            }
    
            &::-webkit-scrollbar
            {
                display: none;
            }
        }
    }
    
    .tab-content {
        float: left;
        padding-top: 24px;
        width: 100%;
        @include able.mediaQuery(viewportXS) {
            padding-top: 65px;
        }
    }
}

.ticket-notification-wrapper {
    margin-bottom: 30px;
    width: 105%;
}

.tplus-event-presale-banner {
    display: flex;
    justify-content: center;
    padding: 26px;
    background-color: able.color(materialBaseBlue);
    margin-bottom: able.spacing(spacing4x);
    .able-icon {
        margin-right: able.spacing(spacing1x);
    }
}