@use "@able/web/src" as able with ($include-breakpointLG: false);
@import '~styles/base.scss';

//R18TODO
.tplus-instructional-box {
  // @include make-col-ready();
  .heading {
    font-size: 28px;
    font-weight: bold;
    letter-spacing: -0.5px;
    line-height: 35px;
    margin: 0 0 32px 0;
    @include able.mediaQuery(viewportXS_plus_SM) {
      font-size: 24px;
      margin-bottom: 24px;
      font-weight: bold;
      letter-spacing: -0.5px;
      line-height: 30px;
    }
  }
  .instructional-box-container {
    margin: 0;
    padding: 0;
    // @include make-row();
    &.two-tiles {
      .instructional-tile {
        @include able.mediaQuery(breakpointMD) {
          // @include make-col(6);
        }
      }
    }
    &.three-tiles {
      .instructional-tile {
        @include able.mediaQuery(breakpointMD) {
          // @include make-col(4);
        }
      }
    }
    &.four-tiles {
      .instructional-tile {
        @include able.mediaQuery(breakpointMD) {
          // @include make-col(3);
        }
      }
    }

    // 2 tile stepper gradients
    [class*='instructional-']:first-child:nth-last-child(2)
      .stepper-container
      .stepper-t-line
      .t-line {
      background: linear-gradient(#a15f9e, #d08cb5 49.69%, #7076b2);
    }

    // 3 tile stepper gradients
    [class*='instructional-']:first-child:nth-last-child(3)
      .stepper-container
      .stepper-t-line
      .t-line {
      background: linear-gradient(#a15f9e, #d789b3);
    }
    [class*='instructional-']:first-child:nth-last-child(3)
      ~ [class*='instructional-']:nth-child(2)
      .stepper-container
      .stepper-t-line
      .t-line {
      background: linear-gradient(#d08cb5, #7076b2);
    }

    // 4 tile stepper gradients
    [class*='instructional-']:first-child:nth-last-child(4)
      .stepper-container
      .stepper-t-line
      .t-line {
      background: linear-gradient(#a15f9e, #d08cb5);
    }
    [class*='instructional-']:first-child:nth-last-child(4)
      ~ [class*='instructional-']:nth-child(2)
      .stepper-container
      .stepper-t-line
      .t-line {
      background: linear-gradient(#d789b3, #d08cb5);
    }
    [class*='instructional-']:first-child:nth-last-child(4)
      ~ [class*='instructional-']:nth-child(3)
      .stepper-container
      .stepper-t-line
      .t-line {
      background: linear-gradient(#d08cb5, #7076b2);
    }

    // Hide the stepper in the last tile
    [class*='instructional-']:last-child .stepper-container {
      svg {
        height: 24px;
      }
      .stepper-t-line {
        display: none;
      }
    }

    .instructional-tile {
      display: flex;
      padding: 0 12px;
      @include able.mediaQuery(breakpointMD) {
        flex-direction: column;
      }
      .stepper-container {
        display: flex;
        flex-direction: column;
        margin-right: 16px;
        @include able.mediaQuery(breakpointMD) {
          margin-bottom: 16px;
        }
        .stepper-t-line {
          height: 100%;
          display: flex;
          justify-content: center;
          padding-top: 8px;
          padding-bottom: 8px;
          @include able.mediaQuery(breakpointMD) {
            display: none;
          }
          .t-line {
            height: 100%;
            width: 2px;
            background: linear-gradient(#5bc5f2, #c69dc9 49.69%, #8779b7);
          }
        }
        svg {
          @include able.mediaQuery(breakpointMD) {
            height: 24px;
          }
          width: 24px;
          height: 34px;
        }
      }
      .tile-content {
        flex-direction: column;
        .tile-headline {
          @include able.TextStyle(HeadingD);
          margin-bottom: 1rem;
        }
        .tile-body {
          @include able.TextStyle(Base);
          margin-bottom: .5rem;
        }
      }
    }
  }
  .secondary-cta {
    margin-top: 24px;
    a {
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }
  .primary-cta {
    margin-top: 24px;
    a {
      line-height: 2rem;
      font-size: 1.3rem;
      text-decoration: none;
      padding-top: 0;
      padding-bottom: 0;
    }
    svg {
      width: 0.9rem;
      height: 0.9rem;
    }
  }
  &__terms {
    @include able.TextStyle(FinePrint);
    margin-top: 1rem;
  }
}
