@use "@able/web/src" as able with ($include-breakpointLG: false);
@import '~styles/base.scss';

.success {
  margin-top: 40px;
  .success-logo {
    text-align: center;
    img {
      width: 60px;
    }
    @include able.mediaQuery(breakpointMD){
      display: none;
    }
  }

  .success-copy {
    position: relative;
    width: 100%;
    padding: 12px 0;
    img {
      display: none;
      position: absolute;
      right: -300px;
      top: 0px;
      @include able.mediaQuery(breakpointMD) {
        display: inline-block;
      }
    }
    @include able.mediaQuery(breakpointMD) {
      padding: 24px 0;
      width: 55%;
    }
  }

  .link-container {
    .primary-cta-22 {
      font-size: 16px;
      letter-spacing: -0.3px;
      @include able.mediaQuery(breakpointMD) {
        margin-right: 48px;
      }
    }
  }

  @include able.mediaQuery(breakpointMD) {
    margin-top: 80px;
  }
}

.decoration {
  margin-top: 80px;
  @include able.mediaQuery(breakpointMD) {
    margin-top: 100px;
  }

  &.success {
    @include able.mediaQuery(breakpointMD) {
      // if the resolution is very low height, low spec laptops etc
      // then this value cannot be a negative otherwise it will "render horribly"
      margin-top: 0;
    }
  }
  background-repeat: repeat-x;
  background-position: -90px 50%;
  min-height: 252px;
  flex: 1;

  @include able.mediaQuery(breakpointMD) {
    min-height: 1069px;
  }
}