@use "@able/web/src" as able with ($include-breakpointLG: false);

$grey: #414141;
$grey-light: #f4f4f4;
$grey-dark: #dadada;
$line-height-20: 20px;
$line-height-22: 22px;
$line-height-23: 23px;
$line-height-28: 28px;
$margin-10: 10px;
$margin-20: 20px;
$padding-10: 10px;
$padding-20: 20px;

@mixin font-size($size) {
  font-size: $size + px;
}

.tcom-global-footer {
  height: auto;
  background-color: $grey-light;
  color: $grey;

  .footer-container {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding: 120px 12px 60px;
    padding-top: 120px;
    padding-bottom: 60px;

    @include able.mediaQuery(breakpointMD) {
      padding-top: 60px;
    }

    .footer-links {
      display: block;
      .footerList {
        border-bottom: 1px solid $grey-dark;
        .accordion-tab {
          .tab-content {
            ul {
              li {
                a {
                  svg {
                    position: relative;
                    top: -2px;
                  }
                }
              }
            }
          }
        }
        .footer-list {
          ul {
            li {
              a {
                svg {
                  position: relative;
                  top: -2px;
                }
              }
            }
          }
        }
      }

      @include able.mediaQuery(breakpointMD) {
        display: flex;
        justify-content: flex-start;
        margin-bottom: $margin-10 * 3;
        .footerList {
          border-bottom: 0;
        }
      }

      .footer-list {
        display: none;

        @include able.mediaQuery(breakpointSM) {
          display: block;
          width: 216px;
          margin-right: $margin-20 + 4;
        }

        @include able.mediaQuery(breakpointMD) {
          margin-right: $margin-10 * 7 + 2;
        }
      }

      // last div having footer-list class
      div:nth-last-child(1) > div.footer-list {
        @include able.mediaQuery(breakpointMD) {
          margin-right: 0;
        }
      }

      /* Accordion */
      .accordion-tab {
        margin-left: -8px;

        @include able.mediaQuery(breakpointMD) {
          display: none;
        }

        .heading-text {
          margin-bottom: 0;
          padding: 20px 0 20px 8px;
          box-sizing: content-box;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        label {
          display: block;
          height: 22px;
          cursor: pointer;
          .accordion-open-icon {
            display: inline-block;
          }
          .accordion-close-icon {
            display: none;
          }
        }

        .tab-content {
          max-height: 0;
          overflow: hidden;
          -webkit-transition: max-height 0.35s;
          -o-transition: max-height 0.35s;
          transition: max-height 0.35s;
          visibility: hidden;

          ul {
            margin-top: 0;
            li:last-child {
              margin-bottom: 8px;
            }
          }
        }

        input {
          position: absolute;
          opacity: 0;
          z-index: -1;
          &[type='checkbox'] {
            &:checked {
              & + label {
                .accordion-close-icon {
                  display: inline-block;
                }
                .accordion-open-icon {
                  display: none;
                }
              }
              & ~ .tab-content {
                max-height: 30em;
                visibility: visible;
              }
            }
            
            &:focus + label {
              border: 1.5px solid;
              border-radius: 3px;
            }
          }
        }
      }
    }
  }

  //Generic classes for footer - all views
  ul {
    list-style: none;
    padding-left: 0;
    margin: 0;

    li {
      margin-bottom: 0;

      @include able.mediaQuery(breakpointMD) {
        margin-bottom: 0;
      }

      a {
        display: flex;
        flex-direction: row;
        align-items: center;
        @include able.TextStyle(Base);
        text-decoration: none;
        padding: ($margin-20 + 8)/2 8px;
        @include able.mediaQuery(breakpointMD) {
          padding: ($margin-10 + 2)/2 8px;
        }
      }

      a:hover {
        text-decoration: underline;
      }

      .footer-icon {
        height: 16px;
        width: 16px;
        margin-left: $margin-10 - 5;
      }
    }
  }

  .heading-text {
    @include able.TextStyle(HeadingD);
    margin-top: 0px;
    margin-bottom: ($margin-10 * 3);

    @include able.mediaQuery(breakpointMD) {
      // @include font-size(20);
      line-height: $line-height-28;
      padding-left: 8px;
    }
  }

  .telstra-social {
    padding-top: $padding-20;

    @include able.mediaQuery(breakpointMD) {
      padding: 0;
    }

    .heading-text {
      margin-bottom: $margin-10 * 3 + 3 !important;

      @include able.mediaQuery(breakpointMD) {
        margin-bottom: $margin-20 + 6 !important;
      }
    }

    ul {
      display: flex;
      flex-wrap: wrap;

      @include able.mediaQuery(breakpointMD) {
        padding-left: 8px;
      }

      li {
        display: inline-block;
        margin-bottom: $margin-20 + 4;
        flex: 0 50%;
        
        @include able.mediaQuery(breakpointSM) {
          flex: none;
          margin-bottom: $margin-10 + 2;
          margin-right: $margin-10 * 3 + 2;
        }

        @include able.mediaQuery(breakpointMD) {
          flex: none;
          margin-bottom: $margin-10 + 2;
          margin-right: $margin-10 * 5 + 6;
        }

        .footer-icon {
          height: 40px;
          width: 40px;
          margin-left: 0;
          opacity: 0.8;
          margin-right: $margin-10 - 5;
        }
        a {
          opacity: 0.8;
          padding: 0;
        }
      }
    }
  }
}

#myBtn {
  display: block;
  position: absolute;
  margin-top: -28px;
  font-size: 16px;
  width: 94px !important;
  height: 56px;
  cursor: pointer;
  padding: 15px;
  text-decoration: none;
  color: #414141;
  background-color: #fff;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.11);
  align-items: center;
  justify-content: center;
  right: 120px;

  @media only screen and (min-width: 320px) and (max-width: 768px) {
    right: 30px;
  }
}

.chevronAtt {
  padding-left: 8px;
}

.btnText {
  float: left;
  font-size: 18px;
  @include able.TextStyle(BaseBig);
}

.btnContainer {
  display: flex;
  position: relative;
}

.btnPosition {
  position: fixed;
  bottom: 60px;
  @media only screen and (min-width: 320px) and (max-width: 768px) {
    bottom: 120px;
  }
}

#showTopBtn {
  @include able.mediaQuery(breakpointMD) {
    margin-left: 92%;
  }

  @media (min-width: 768px) and (max-width: 991.98px) {
    margin-left: 85%;
  }
}