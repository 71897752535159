// icons
.icon-earn-st0 {
  fill: none;
}
.icon-earn-st1 {
  fill: #434343;
}

.icon-redeem-st0 {
  fill: none;
}
.icon-redeem-st1 {
  fill: #424242;
}

.icon-reward-st1 {
  fill: currentColor;
}

.icon-reward-st0 {
  fill: none;
}
