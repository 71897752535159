@use "@able/web/src" as able with ($include-breakpointLG: false);

@import '~styles/base.scss';
.alert-box-container{
    // @include make-col(12);
    @include able.mediaQuery(viewportXS_plus_SM) {
        padding:24px 24px 24px 16px;;
     }
    display:flow-root;
    padding:24px 24px 24px 16px;
    .alert{
        float:left;
        margin-right:10px;
        // @include make-col(2);
    }
    .alert-text{
        float:left;
        font-size: 14px;
        font-weight: 300;
        letter-spacing: -0.15px;
        line-height: 25px;
        font-family: $font-family;
        color: #414141;
        // @include make-col(10);
    }
    .signin{
        cursor:pointer;
        text-decoration: underline;
        color:#a0148c;
    }
    .header-text{
        font-weight:$font-weight-bold;
    }
    &.info{
        border-left:3px solid #0064D2;
        background:#ffffff;
        box-shadow:  0 0 10px  rgba(0,0,0,0.12);
        -moz-box-shadow: 0 0 10px  rgba(0,0,0,0.12);
        -webkit-box-shadow: 0 0 10px  rgba(0,0,0,0.12);
        -o-box-shadow: 0 0 10px  rgba(0,0,0,0.12);
    }
    &.warning{
        border-left:3px solid #D0021B;   
        background:#ffffff;
        box-shadow:  0 0 10px  rgba(0,0,0,0.12);
        -moz-box-shadow: 0 0 10px  rgba(0,0,0,0.12);
        -webkit-box-shadow: 0 0 10px  rgba(0,0,0,0.12);
        -o-box-shadow: 0 0 10px  rgba(0,0,0,0.12);
    }
}