@import '~styles/base.scss';
.technical-issue {
  .icon-contain {
    margin: 24px 0;
    > img {
      width: 100px;
      height: auto;
    }
  }
}
