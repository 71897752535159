@use "@able/web/src" as able with ($include-breakpointLG: false);
@import '~styles/base.scss';

.thumbnail-description{
    color:$text-color-primary;
    //display:inline-block;
    //width:360px;
    @include able.mediaQuery(breakpointMD) {
        display:inline-block;
        float:left;
    }
    .tour-image {
        width: 100%;
        height: 270px;
        object-fit: contain;
    }
    
    .font-details{
        letter-spacing: -0.13px;
        line-height: 22px;
        font-weight: $font-weight-light;
        font-size:$font-size-h5;
        &.sale-details {
            width: 100%;
            float: left;
            padding-bottom: 24px;
            .bold, b{
                font-weight: bold;
            }
        }
        &.short-desc{
            width: 100%;
            white-space: pre-wrap;
        }
        &.ellipsis{
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
        }
        .presale-style {
            margin-bottom: 20px;
        }
        .event-venue {
            font-weight: 700;
        }
    }
    
    .readmore {
        margin-top: 10px;
        display: block;
        cursor: pointer;
        margin-left: -0.5rem;
    }
    .readmore-none{
        display:none;
    }

}

@include able.mediaQuery(viewportXS) {
    .thumbnail-description {
        width:100%;

        .tour-image {
            width:64px;
            height:64px;
        }

        .image-container {
            margin-right:15px;
            float:left;
        }

        .font-details {
            &.sale-details {
                font-size:$font-size-h6;
                width:100%;
                letter-spacing: -0.2px;
                line-height: 20px;
            }
            &.short-desc {
                width:100%;
                letter-spacing: -0.13px;
                line-height: 22px;
                margin-top:24px;
            }
        }

        // thumbnail for movies in mobile view
        &.movie {
            display: flex;
            .tour-image {
                height: 84px;
            }

            .content-wrapper {
                display: flex;
                flex-direction: column;
                .font-details {
                    &.short-desc {
                        margin-top: 0;
                    }
                }
                .edit-details {
                    margin-top: auto;
                }
            }
        }
    }
}
