@use "@able/web/src" as able;

.tplus-promo-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 16px;

  @include able.mediaQuery(viewportXS_plus_SM) {
    flex-direction: column;
    padding-right: 0;
  }

  .tplus-promo-content {
    width: 100%;
    padding-left: 40px;

    @include able.mediaQuery(viewportXS_plus_SM) {
      padding-left: 0;
    }

    &-right {
        padding-left: 0px;
    }

    .tplus-promo-heading-small {
      @include able.TextStyle(Tag);

      margin-top: 16px;
      color: able.colour(textSubtle);
    }

    .tplus-promo-heading {
        @include able.TextStyle(HeadingB);
        margin-top: 8px;
    }

    .tplus-promo-body {
        @include able.TextStyle(BaseBig);

        margin-top: 16px;

      @include able.mediaQuery(viewportXS_plus_SM) {
        margin-top: 8px;
      }
    }

    .tplus-promo-terms-conditions {
        @include able.TextStyle(FinePrint);
        margin-top: 16px;
    }

    .tplus-promo-links-container {
      width: 100%;
      display: grid;
      grid-template-columns: auto 1fr;
      column-gap: 24px;
      text-align: left;
      line-height: 0;

      @include able.mediaQuery(viewportXS) {
        width: 100%;
        text-align: left;
        line-height: 0;
        display: block;
      }

      @include able.mediaQuery(viewportXS_plus_SM_plus_MD) {
        &.tplus-promo-links-container-tertiary {
          .tplus-promo-link {
            margin-top: 16px;
          }
        }
      }

      @include able.mediaQuery(breakpointLG) {
        &.tplus-promo-links-container-tertiary {
          .tplus-promo-link--half {
            margin-top: 16px;
          }
        }
      }

      .tplus-promo-link {
        margin-top: 24px;
        @include able.mediaQuery(breakpointSM) {
            display: inline-block;
        }

        .button-tplus {
            @include able.mediaQuery(viewportXS) {
                width: 100%
            }
        }
      }
    }
  }

  .call-out-block {
    @include able.TextStyle(Label);

    height: able.spacing(spacing4x);
    padding: able.spacing(spacingHalf) able.spacing(spacing2x) able.spacing(spacingHalf) able.spacing(spacing1x);
    background-color: able.colour(materialBaseBrandTertiary);
    border-top-left-radius: 4px;
    border-bottom-right-radius: 32px;
    border-top-right-radius: 32px;
    border-bottom-left-radius: 0;
    display: flex;
    align-items: center;
    line-height: 18px;
    position: absolute;
    top: 0;
    color: able.colour(Base);
    font-size: 14px;
    font-weight: bold;

    @media screen and (max-width: 320px) {
      height: 30px;
    }

    svg {
      margin-right: 8px;
      margin-left: 0;
      transform: none;
    }

    .tplus-promo-callout-no-text {
      margin-left: able.spacing(spacingHalf);
    }
  }

  .image-wrapper {
    position: relative;
    line-height: 0;
    width: 100%;
  }

  .tplus-promo-image {
    border-radius: 0.75rem;
    overflow: hidden;
    width: 100%;

    img {
      width: 100%;
      min-width: 536px;
    }

    @include able.mediaQuery(viewportXS_plus_SM_plus_MD) {
      img {
        min-width: 440px;
      }
    }

    @include able.mediaQuery(viewportXS_plus_SM) {
      img {
        min-width: 656px;
      }
    }

    @include able.mediaQuery(viewportXS) {
      img {
        min-width: 0;
      }
    }
  }

  &.tplus-promo-container-right {
    flex-direction: row-reverse;

    @include able.mediaQuery(viewportXS_plus_SM) {
      flex-direction: column;
    }

    .tplus-promo-content {
    //   margin-left: 0;
        padding-right: 40px;
    //   padding-left: 0;
    }
  }

  &.tplus-promo-container-half:last-child {
    padding-bottom: 48px;

    @include able.mediaQuery(breakpointMD) {
      padding-bottom: 0;
    }
  }

  .tplus-vertical-tabs-container > & {
    .tplus-promo-image {
      width: 100%;
      margin-left: 0;

      img {
        width: 100%;
      }
    }
  }

  @include able.mediaQuery(breakpointMD) {
    justify-content: center;

    .tplus-promo-image-mobile {
      display: none;
    }

    /** PROMO Half **/
    &.tplus-promo-container-half {
      flex-direction: column;
      justify-content: flex-start;

      .tplus-promo-image {
        margin-left: 0;
        margin-right: 0;
        text-align: right;
        width: 100%;
        height: auto;

        img {
          width: 100%;
          min-width: unset;
        }
      }

      .tplus-promo-content {
        padding: 0;
        margin-left: 0;
        margin-right: 0;
        width: 100%;
        margin-top: 16px;

        p:not(.tplus-promo-terms-conditions) {
            @include able.TextStyle(Base, Paragraph);
        }
      }
    }
  }

  @include able.mediaQuery(breakpointLG) {
    &.tplus-promo-container-half {
      padding-right: 16px;
    }
  }

  @include able.mediaQuery(breakpointLG) {
    flex-direction: row;
    justify-content: center;

    &.tplus-promo-container-half {
      flex-direction: column;
      justify-content: flex-start;
      padding-right: 24px;
      padding-left: 0;

      .tplus-promo-content {
        width: 100%;
        margin-top: 16px;

        p:not(.tplus-promo-terms-conditions) {
            @include able.TextStyle(Base, Paragraph);
        }
      }
    }
  }
}

.tplus-vertical-tabs-container .tplus-promo-container {
  flex-flow: wrap;
  justify-content: flex-start;
  padding: 0 8px;

  .tplus-promo-content {
    margin-left: 0;

    p:not(.tplus-promo-terms-conditions) {
        @include able.TextStyle(Base);
    }
  }

  .tplus-promo-image {
    width: 100%;
    min-height: 270px;
    margin-left: 0;

    img {
      width: 100%;
    }
  }

  @include able.mediaQuery(breakpointMD) {
    justify-content: flex-start;

    .tplus-promo-image {
      width: 544px;
      padding: 0;
    }
  }

  @include able.mediaQuery(breakpointLG) {
    padding: 0;

    .tplus-promo-image {
      width: 100%;
    }
  }
}

// removing padding of last element in the half promo
.tplus-layout-container__component .promotile:last-child .tplus-promo-container-half {
  padding-bottom: 0;
}

.tplus-promo-container__callout use {
  padding-left: able.spacing(spacingHalf);
  fill: able.colour(Base);
}
