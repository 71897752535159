@use "@able/web/src" as able;

.tplus-rewards-slider {
  margin-top: able.spacing(spacing3x);
  .current-price {
    margin-top: able.spacing(spacingHalf);
    &.hot-offer {
      color: #a0148c;
    }
  }
  
  .original-price {
    text-decoration: line-through;
  }
}
.tplus-rewards-slider #slider-heading {
  font-size: 24px;
  margin-bottom: 10px;
}
.capacity-heading #heading-label {
  font-size: 24px;
  margin-bottom: 10px;
}
.currrent-price-section .current-price {
  color: rgb(40, 40, 40);
  font-size: 24px;
  font-weight: bold;
  height: 34px;
  letter-spacing: 0px;
  line-height: 34px;
  text-align: center;
  padding-top: 24px;
}
#page-step-comp .able-PageStepper--Fluid > div > div div span:last-child {
  background: linear-gradient(90deg, rgb(0, 153, 248) 0%, rgb(94, 80, 191) 100%);
}
#page-step-comp .able-PageStepper--Fluid > div > div p:first-child {
  display: none;
}
.page-step-comp {
  width: 100%;
}

.tplus-rewards-slider {
  .button-minus {
    left: 17px;
  }
  .button-tplus {
    right: 17px;
  }
}

#page-step-comp .able-PageStepper--Fluid > div {
  width: 100%;
}

#page-step-comp .able-PageStepper--Fluid > div > div {
  padding-bottom: 3.5rem;
}

#page-step-comp .able-PageStepper--Fluid {
  border-bottom: none;
}

#rewards-slider .button-plus-minus {
  margin-bottom: 2.5rem;
  @include able.IconButton();
  height: 7.5rem;
}
.tplus-rewards-slider {
  margin-bottom: -68px;
}
@media (max-width: 768px) {
  .tplus-rewards-slider #slider-heading {
    font-size: 24px;
    margin-bottom: 10px;
  }
  .tplus-rewards-slider {
    margin-bottom: -38px;
  }
  .capacity-heading #heading-label {
    font-size: 24px;
    margin-bottom: 10px;
  }
  .currrent-price-section .current-price {
    color: rgb(40, 40, 40);
    font-size: 24px;
    font-weight: bold;
    height: 34px;
    letter-spacing: 0px;
    line-height: 34px;
    text-align: center;
    padding-top: 24px;
  }
  #page-step-comp .able-PageStepper--Fluid > div > div div span:last-child {
    background: linear-gradient(90deg, rgb(0, 153, 248) 0%, rgb(94, 80, 191) 100%);
  }
  #page-step-comp .able-PageStepper--Fluid > div > div p:first-child {
    display: none;
  }
  .page-step-comp {
    width: 100%;
  }
  .button-minus {
    left: 3px;
  }
  .button-tplus {
    right: 3px;
  }
  #page-step-comp .able-PageStepper--Fluid > div {
    width: 100%;
  }
  #page-step-comp .able-PageStepper--Fluid {
    border-bottom: none;
  }

  #rewards-slider .button-plus-minus {
    margin-bottom: 0.6rem;
    @include able.IconButton();
    height: 7.5rem;
  }
}
