@use "@able/web/src" as able with ($include-breakpointLG: false);

// Package Style Sheet
@import '~styles/base.scss';
@import '~normalize.css';

// Global
@import '@able/react/dist/able-react-without-LG.min';
@import '@able/web/dist/able-web.css';
@import 'styles/layout.scss';
@import 'styles/icons.scss';
@import 'styles/buttons.scss';
@import 'styles/richtext.scss';

html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

button {
  background: none;
  border: 0;
  color: able.colour(interactiveForegroundNormal);
}

body {
  margin: 0;
  padding: 0;
  font-family: $font-family;

  // base font stylings
  font-size: $font-size-body-copy;
  color: $text-color-primary;
  font-weight: $font-weight-normal;
  letter-spacing: $font-body-copy-letter-spacing;
  background-color: white;
}

img {
  max-width: 100%;
}

p {
  line-height: 22px;
}

h1 {
  @include heading1;
  color: $text-color-primary;
}

ul {
  margin: 0;
  padding: 0;
}

a {
  color: able.colour(interactiveForegroundNormal);
  cursor: pointer;
}

small {
  font-size: 0.875rem;
  color: #757575;
  letter-spacing: -0.2px;
  line-height: 1.25rem;
}

.white-text {
  color: #fff;
}

.cta-terms {
  @include able.TextStyle(FinePrint, Subtle);
}

.hidden {
  display: none;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  white-space: nowrap;
  border: 0;
}