@use "@able/web/src" as able;

$textInteractiveHover: #001e82;

.tplus-accordion-group {
  margin-bottom: 0;

  &__group {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__heading {
    width: 100%;
    margin: 0;
    padding: 0;
  }

  &__item {
    position: relative;
    border-bottom: 1px solid able.colour(dividerSubtle);

    &:first-child {
      border-top: 1px solid able.colour(dividerSubtle);
    }

    button:focus {
      outline: 1px solid able.colour(borderActive);
    }

    &__title {
      cursor: pointer;
      display: flex;
      position: relative;
      padding: 32px 32px 32px 8px;
      background: none;
      border: none;
      width: 100%;
      text-align: left;
      scroll-behavior: unset;
      scroll-margin-top: 0;

      @include able.TextStyle(Label);

      &:hover {
        color: $textInteractiveHover !important; // Able needs to fix their dialog icon class hiearchy 

        svg {
          fill: $textInteractiveHover !important; // Able needs to fix their dialog icon class hiearchy 
        }
      }

      .icon-chevron-down {
        width: 1.5rem;
        height: 1.5rem;
        vertical-align: middle;
        margin-right: 0.25rem;
        outline: none;
        position: absolute;
        right: 5px;
        left: auto;
        margin-left: 32px;
        transition: all 0.4s ease-in;
        align-self: center;
        use {
          display: block;
        }
      }

      &--active {
        .icon-chevron-down {
          transform: rotateX(180deg) !important;
          fill: able.colour(borderActive) !important;
        }

        color: able.colour(borderActive) !important;
      }
    }

    &__content {
      visibility: hidden;
      height: 0;
      opacity: 0;
      overflow: hidden;
      transition: 350ms ease-in-out;
      padding: 0 8px;

      > * {
        margin: 16px 0;
        font-size: 18px;
        font-weight: 300;
      }

      &--visible {
        visibility: visible;
        padding: 0 8px;
        height: auto;
        opacity: 1;
      }
    }
  }
}