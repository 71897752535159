@use "@able/web/src" as able with ($include-breakpointLG: false);

.tplus-jego-page {
  padding-top: 0;

  &__main {
    .tplus-jego-component.able-column {
      @include able.mediaQuery(breakpointMD) {
        margin-bottom: able.spacing(spacing10x);
      }
      margin-bottom: able.spacing(spacing7x);
    }
  }
  &__narrow {
    .tplus-jego-component.able-column {
      @include able.mediaQuery(breakpointMD) {
        margin-bottom: able.spacing(spacing7x);
      }
      margin-bottom: able.spacing(spacing5x);
    }
  }
}


.able-Grid {
  li.able-column {
    list-style: none;
  }
  .tplus-jego-component.able-column {
    &.no-spacing {
      margin-bottom: 0;
    }
    &.tplus-bottom {
      &__spacing1x {
        margin-bottom: able.spacing(spacing1x);
      }
      &__spacing2x {
        margin-bottom: able.spacing(spacing2x);
      }
      &__spacing3x {
        margin-bottom: able.spacing(spacing3x);
      }
      &__spacing4x {
        margin-bottom: able.spacing(spacing4x);
      }
      &__spacing5x {
        margin-bottom: able.spacing(spacing5x);
      }
      &__spacing7x {
        margin-bottom: able.spacing(spacing7x);
      }
      &__spacing10x {
        margin-bottom: able.spacing(spacing10x);
      }
      &__none {
        margin-bottom: 0;
      }
    }
    &.tplus-bottom-md {
      &__spacing1x {
        @include able.mediaQuery(breakpointMD) {
          margin-bottom: able.spacing(spacing1x);
        }
      }
      &__spacing2x {
        @include able.mediaQuery(breakpointMD) {
          margin-bottom: able.spacing(spacing2x);
        }
      }
      &__spacing3x {
        @include able.mediaQuery(breakpointMD) {
          margin-bottom: able.spacing(spacing3x);
        }
      }
      &__spacing4x {
        @include able.mediaQuery(breakpointMD) {
          margin-bottom: able.spacing(spacing4x);
        }
      }
      &__spacing5x {
        @include able.mediaQuery(breakpointMD) {
          margin-bottom: able.spacing(spacing5x);
        }
      }
      &__spacing7x {
        @include able.mediaQuery(breakpointMD) {
          margin-bottom: able.spacing(spacing7x);
        }
      }
      &__spacing10x {
        @include able.mediaQuery(breakpointMD) {
          margin-bottom: able.spacing(spacing10x);
        }
      }
      &__none {
        margin-bottom: 0;
      }
    }
  }
}