@use "@able/web/src" as able with ($include-breakpointLG: false);

.tplus-points-history-table {
  position: relative;
  border-collapse: collapse;
  width: 100%;

  thead {
    border-top: 0;
  }

  th {
    text-align: left;
    padding-bottom: 12px;

    &:last-child {
      text-align: right;
      padding-right: 24px;
    }
  }

  td {
    border-top: 1px solid able.colour(dividerSubtle);
    padding: 18px 0;

    .info {
      position: relative;
      display: block;
      width: 100%;
      padding-left: 36px;

      .item-icon {
        position: absolute;
        top: 12px;
        left: 0;
        width: 24px;
        height: 24px;
      }

      span {
        margin: 12px 0;
      }

      .item-date {
        margin-bottom: 6px;
        font-style: italic;
      }
      .item-label {
        margin-right: 12px;
      }
      .item-price {
        display: block;
        padding-top: 24px;
      }
    }
  }

  td {
    &:last-child {
      position: relative;
      text-align: right;
      right: 6px;
      padding-right: 24px;
    }
  }
}
