@use "@able/web/src" as able;

.tplus-rewards-product-tile {
  list-style: none;
  margin-bottom: able.spacing(spacing4x);
  @include able.mediaQuery(breakpointSM) {
    margin-bottom: able.spacing(spacing7x);
  }
  &__container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  &__content {
    position: relative;
    height: 100%;
    &.hot-offer {
      border-right: 1px solid able.colour(dividerEmphasis);
      border-left: 1px solid able.colour(dividerEmphasis);
      border-bottom: 1px solid able.colour(dividerEmphasis);
    }
    &:not(.hot-offer) {
      padding-top: 40px;
    }
    &:hover {
      box-shadow: 0 -4px 0 #0064D2 inset;
    }
  }
  &__promo--text {
    @include able.TextStyle(HeadingD);
    @include able.mediaQuery(breakpointMD) {
      padding: 6px 10px 6px 10px;
    }
    padding: 9px 0 9px 10px;
    height: 40px;
    color: #fff;
    background-color: able.colour(interactiveForegroundNormal);
    .able-icon use {
      fill: #fff;
    }
  }
  &__image {
    &.outofstock {
      opacity: .4;
    }
  }
  &__link {
    text-decoration: none;
    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
    }
  }
  &__lozenge-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  &__lozenge {
    margin-bottom: able.spacing(spacing1x);
    margin-right: able.spacing(spacing1x);
    &.outofstock {
      @include able.Lozenge(Neutral);
    }
    &.gold-exclusive {
      @include able.Lozenge(TPlusGold);
      color: #fff;
    }
    &.silver-exclusive {
      @include able.Lozenge(NeutralEmphasis);
      color: #fff;
    }
    &.member-exclusive, &.business-exclusive {
      @include able.Lozenge(Featured);
      color: #fff;
    }
  }
}