@use "@able/web/src" as able with ($include-breakpointLG: false);


.address-input-wrapper {
  display: flex;
  flex-direction: column;
  width: auto;
  margin: 0;
}

.address-search-dropdown {
  overflow-y: auto;
  margin: 0;
  border-top: 0;
  z-index: 1000;
  list-style: none;
  padding: 0;

  li {
    cursor: pointer;
    margin-left: 3px;
    padding: 16px 24px;
    &.selected {
      margin-left: 0;
      border-left: 4px solid able.colour(borderActive);
      p{
      font-weight: 700;
      }
   
    }
  }
  mark.highlight {
    background-color: inherit;
    font-weight: 600;
  }
}

div[aria-expanded='true'] {
  .address-search-dropdown {
    @include able.Surface($surface-name: SurfaceLow);
  }
}

div[aria-expanded='true']
.address-search-dropdown {
  background-color: #fff;
  box-shadow: 0 4px 5px 0 rgba(0,4,10,.14),
              0 1px 10px 0 rgba(0,4,10,.04),
              0 2px 4px 0 rgba(0,4,10,.2);
  margin-bottom: 1rem;

  @include able.mediaQuery(breakpointMD) {
    box-shadow: 0 6px 10px 0 rgba(0,4,10,.14),
              0 1px 18px 0 rgba(0,4,10,.04),
              0 3px 5px -1px rgba(0,4,10,.2);
    margin-bottom: 1.5rem;
  }
}

