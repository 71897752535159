@use "@able/web/src" as able with ($include-breakpointLG: false);
@import '~styles/base.scss';

@include able.mediaQuery(breakpointMD) {
  .in-focus > {
    .plus-rewards-tile {
      opacity: 0.75;
    }
  }
}

.plus-rewards-tile {
  opacity: 1;
  list-style: none;
  text-decoration: none;
  padding-bottom: 2rem;
  margin-top: 2rem;
  position: relative;

  @include able.mediaQuery(breakpointMD) {
    &:hover {
      opacity: 1;
      border-bottom: 2px solid able.colour(interactiveForegroundNormal);
    }
  }

  img {
    width: 100%;
  }

  h3 {
    @include able.TextStyle(HeadingD);
    margin-bottom: 1.5rem;
  }

  
  p {
    @include able.TextStyle(Base);
    margin-bottom: 1rem;
    position: relative;
  }

  &__link {
    text-decoration: none;
    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
    }
  }

  &__brand {
    @include able.TextStyle(Tag);
    display: block;
    margin-bottom: 0.5rem;
  }
}

@include able.mediaQuery(breakpointMD) {
  .plus-rewards-tile {
    border-bottom: 2px solid transparent;
    margin-top: 0;
  }
}
