@use "@able/web/src" as able with ($include-breakpointLG: false);

@mixin marginBottomSpacing($spacing) {
  .tplus-jego-component.able-column {
    margin-bottom: able.spacing($spacing);
  }
}

.tplus-full-width-container-main {
  position: relative;
}

.tplus-full-width-container {
  width: 100vw;
  height: 100%;
  position: absolute;
  top: 0;
  opacity: o;
  left: calc(-50vw + 50%);
  >.able-Grid.container {
    margin: 0 auto;
  }

  &_true {
    background-color: able.colour(accentBackgroundHighlight);
  }
}

.tplus-full-width-container-overlay {
  z-index: 1;
  position: relative
}

.tplus-layout-container {
  &__carousel { 
    width: 100%;
    display: block;
  }
  
  &__carousel-item { 
    width: calc(100% + 24px) !important;
    padding: 0 12px;
    margin-left: -12px;
  }

  &__content {
    display: flex;
    &.center-align {
      justify-content: center;
    }  
    &.right-align {
      justify-content: right;
    }
    &.children-spacing1x {
      @include marginBottomSpacing(spacing1x);
    }
    &.children-spacing2x {
      @include marginBottomSpacing(spacing2x);
    }
    &.children-spacing3x {
      @include marginBottomSpacing(spacing3x);
    }
    &.children-spacing4x {
      @include marginBottomSpacing(spacing4x);
    }
    &.children-spacing5x {
      @include marginBottomSpacing(spacing5x);
    }
    &.children-spacing7x {
      @include marginBottomSpacing(spacing7x);
    }
    &.children-spacing10x {
      @include marginBottomSpacing(spacing10x);
    }
    &.children-md-spacing1x {
      @include able.mediaQuery(breakpointMD) {
        @include marginBottomSpacing(spacing1x);
      }
    }
    &.children-md-spacing2x {
      @include able.mediaQuery(breakpointMD) {
        @include marginBottomSpacing(spacing2x);
      }
    }
    &.children-md-spacing3x {
      @include able.mediaQuery(breakpointMD) {
        @include marginBottomSpacing(spacing3x);
      }
    }
    &.children-md-spacing4x {
      @include able.mediaQuery(breakpointMD) {
        @include marginBottomSpacing(spacing4x);
      }
    }
    &.children-md-spacing5x {
      @include able.mediaQuery(breakpointMD) {
        @include marginBottomSpacing(spacing5x);
      }
    }
    &.children-md-spacing7x {
      @include able.mediaQuery(breakpointMD) {
        @include marginBottomSpacing(spacing7x);
      }
    }
    &.children-md-spacing10x {
      @include able.mediaQuery(breakpointMD) {
        @include marginBottomSpacing(spacing10x);
      }
    }
  }

  &__cta-container {
    display: flex;
    &.center-align {
      justify-content: center;
    }
    &.right-align {
      justify-content: right;
    }  
  }

  &__heading__container {
    display: flex;
    margin-bottom: 2rem;
    flex-wrap: wrap;
    h1 {
      @include able.TextStyle(HeadingA);
    }
  
    h2 {
      @include able.TextStyle(HeadingB);
    }
  
    h3 {
      @include able.TextStyle(HeadingD);
    }
    &.center-align {
      justify-content: center;
      .tplus-layout-container {
        &__heading, &__body-copy {
          text-align: center;
        }
      }
    }
    &.right-align {
      justify-content: right;
      .tplus-layout-container {
        &__heading, &__body-copy {
          text-align: right;
        }
      }
    }  
  }
  &__heading {
    flex: 0 0 100%;
    margin-bottom: able.spacing(spacing2x);
  }
  
  &__body-copy {
    flex: 0 0 100%;
    margin-top: 1rem;
    @include able.TextStyle(Base);
  }
}