@use "@able/web/src" as able;

.notification-bar {
  position: relative;
  height: 0;
  align-items: center;
  background: #e5effa;
  transition: all 0.2s ease;
  overflow: hidden;
  width: 100%;
  z-index: 2;

  .notification-text-wrapper {
    flex-basis: 100%;
    text-align: left;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: -0.2px;
    line-height: 22px;
    padding: 16px 0 16px 16px;
    .notification-text {
      text-align: left;
      margin-bottom: 0;
      width: 95%;
    }
    .notification-copy {
      display: inline;
    }
  }

  .notification-close-btn {
    position: relative;
    top: 4px;
    cursor: pointer;
    align-self: flex-start;
    background: none;
    border: 0;
  }

  @include able.mediaQuery(breakpointMD) {
    .notification-text-wrapper {
      text-align: center;
      .notification-text {
        display: inline;
      }

      .notification-cta {
        margin-left: 5px;
      }
    }
    .notification-close-btn {
      align-self: center;
      margin: 0 15px 0 0;
    }
  }
  &.visible {
    position: relative;
    display: flex;
    height: 144px;
    transition: height 0.2s ease-in;

    @include able.mediaQuery(breakpointMD) {
      height: 70px;
    }
  }

  &.hidden {
    height: 0;
    transition: all 0.2s ease-in;
    border-bottom: 0px solid transparent;
  }
}
