.tplus-rewards-filter {
  &[aria-hidden=true] {
    display: none;
  }
  .able-CheckboxGroup {
    margin-top: 0;
  }
  #product-filters {
    .tplus-accordion-group__item {
      border-top: 0;
    }
  }
}
.each-filter {
  display: -webkit-inline-box;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 20px;
  padding-right: 20px;
  width: auto;
  height: auto;
  border: 1px solid rgb(0, 100, 210);
  border-radius: 26px;
}
.each-filter-text {
  padding-right: 3px;
  color: rgb(0, 100, 210);
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0.15px;
  line-height: 22px;
}
.each-filter-pseudo:after{
  display: inline-block;
  content: "\00d7";
}
.each-filter-pseudo {
  height: 20px;
  width: 20px;
  color: rgb(0, 100, 210);
  padding-left: 3px;
}
.clear-all {
  color: rgb(0, 98, 218);
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0px;
  line-height: 24px;
  padding-left: 0px;
  text-decoration: underline;
}
.filter-section {
  padding-bottom: 16px;
}
.dialog-heading {
  display: flex;
  padding-bottom: 20px;
}
.icon-close {
  margin-left: auto;
}
.tplus-rewards-border-hide .tplus-accordion-group__item:first-child {
  border-top: 0;
}
.icon-close #Mask {
  fill: #0064d2;
}
.icon-close svg {
  width: 32px;
  height: 32px;
}
.icon-close {
  position: absolute;
  right: 20px;
  top: 15px;
}